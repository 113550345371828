<template>
    <div>
        <div v-if="dataFetchInfo">
            <p>Sedang memuat..</p>
        </div>
        <div v-if="!dataFetchInfo">            
            <div class="page-header">
                <div class="page-block">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <h5 class="m-t-10">
                                Data Peserta<br/>
                                <small>{{ dataInfo.title }}</small><br/>
                                <small>Tahun Ajaran: {{ dataInfo.tahun_ajaran }}</small><br/>
                            </h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a href="javascript:void(0)" v-on:click="getData()" class="btn btn-primary rounded mr-2">Muat Ulang</a>
                                <a href="javascript:void(0)" v-on:click="goBack()" class="btn btn-outline-primary rounded">Kembali</a>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Status</label>
                                        <select class="form-control" v-model="status" v-on:change="getData()">
                                            <option value="">Filter Status</option>
                                            <option value="belum_melengkapi">Belum Melengkapi Data</option>
                                            <option value="menunggu_verifikasi">Menunggu Verifikasi Data</option>
                                            <option value="lolos_verifikasi">Lolos Verifikasi</option>
                                            <option value="gagal_verifikasi">Gagal Verifikasi (Ditolak)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Kata kunci</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div v-if="dataFetch">
                                        <p>Sedang mengambil data...</p>
                                    </div>

                                    <div v-if="(!dataFetch && dataList.length > 0)"  class="table-scroll-x-fixed table-scroll-max-h bg-white" style="max-height: 450px; overflow-y: auto;">
                                        <table class="table table-bordered table-hover table-thead-sticky table-th-sticky" style="font-size:13px">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%; background-color: #f1f1f1;" class="text-center sticky-col sticky-th">No</th>
                                                    <th style="background-color: #f1f1f1;" class="sticky-col first-col sticky-th">Nama Peserta</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Identitas Siswa</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Orang Tua/Wali</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Sekolah Asal</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Peminatan<br/>& Nilai Rapor</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Upload Berkas</th>
                                                    <th style="background-color: #f1f1f1;" class="text-center">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center sticky-col">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td class="sticky-col first-col">
                                                        {{ item.fullname }}
                                                        <br/>
                                                        <small>NISN: {{ item.nisn }}</small><br/>
                                                        <small>No Pendaftaran: {{ item.no_pendaftaran }}</small>
                                                        <hr/>
                                                        <small>
                                                            Status Pendaftaran:
                                                            <span v-if="item.status_pendaftaran == 'belum_melengkapi'" class="badge badge-danger">Belum Lengkap</span> 
                                                            <span v-if="item.status_pendaftaran == 'menunggu_verifikasi'" class="badge badge-warning" style="color: #222 !important;">Menunggu Verifikasi</span> 
                                                            <span v-if="item.status_pendaftaran == 'lolos_verifikasi'" class="badge badge-success" style="color: #222 !important;">Lolos Verifikasi</span> 
                                                            <span v-if="item.status_pendaftaran == 'gagal_verifikasi'" class="badge badge-danger">Gagal Verifikasi (Ditolak)</span> 
                                                            <br/>
                                                            <a href="javascript:void(0)" data-toggle="modal" data-target="#modal_form_status" v-on:click="setFormStatus(item.status_pendaftaran, item.no_pendaftaran, item.fullname, item.id, item.ppdb_formulir_id, item.users_id)">
                                                                <i class="fa fa-edit m-r-2"></i> Ubah Status Pendaftaran
                                                            </a>
                                                            <br/>
                                                            <a href="javascript:void(0)" v-on:click="setId(item.users_id)"  data-toggle="modal" data-target="#modalNewPasswordAdmin">
                                                                <i class="fa fa-lock m-r-2"></i> Ganti Password
                                                            </a>
                                                        </small>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.identitas_done == 0">(Belum Lengkap ❌)</span>
                                                        <span v-if="item.identitas_done == 1">(Sudah Lengkap ✔️)</span>
                                                        <hr v-if="item.identitas_done == 1"/>
                                                        <div v-if="item.identitas_done == 1">
                                                            <button data-toggle="modal" data-target="#modal_identitas" v-on:click="openData('identitas', item.users_id)" type="button" class="btn rounded btn-sm btn-outline-primary btn-block">Lihat Data</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.ortu_done == 0">(Belum Lengkap ❌)</span>
                                                        <span v-if="item.ortu_done == 1">(Sudah Lengkap ✔️)</span>
                                                        <hr v-if="item.ortu_done == 1"/>
                                                        <div v-if="item.ortu_done == 1">
                                                            <button data-toggle="modal" data-target="#modal_ortu" v-on:click="openData('ortu', item.users_id)" type="button" class="btn rounded btn-sm btn-outline-primary btn-block">Lihat Data</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.sekolah_done == 0">(Belum Lengkap ❌)</span>
                                                        <span v-if="item.sekolah_done == 1">(Sudah Lengkap ✔️)</span>
                                                        <hr v-if="item.sekolah_done == 1"/>
                                                        <div v-if="item.sekolah_done == 1">
                                                            <button data-toggle="modal" data-target="#modal_sekolah" v-on:click="openData('sekolah', item.users_id)" type="button" class="btn rounded btn-sm btn-outline-primary btn-block">Lihat Data</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.rapor_done == 0">(Belum Lengkap ❌)</span>
                                                        <span v-if="item.rapor_done == 1">(Sudah Lengkap ✔️)</span>
                                                        <hr v-if="item.rapor_done == 1"/>
                                                        <div v-if="item.rapor_done == 1">
                                                            <button data-toggle="modal" data-target="#modal_rapor" v-on:click="openData('rapor', item.users_id, item.ppdb_formulir_id)" type="button" class="btn rounded btn-sm btn-outline-primary btn-block">Lihat Data</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.berkas_done == 0">(Belum Lengkap ❌)</span>
                                                        <span v-if="item.berkas_done == 1">(Sudah Lengkap ✔️)</span>
                                                        <hr v-if="item.berkas_done == 1"/>
                                                        <div v-if="item.berkas_done == 1">
                                                            <button data-toggle="modal" data-target="#modal_berkas" v-on:click="openData('berkas', item.users_id)" type="button" class="btn rounded btn-sm btn-outline-primary btn-block">Lihat Data</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button :disabled="is_download" v-on:click="downloadHasilFormulir(item.users_id, item.fullname, item.no_pendaftaran)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-primary btn-block">
                                                            <span>{{ is_download ? 'Loading...':'Download Hasil Formulir' }}</span>
                                                            <span class="ml-auto">🖨️</span>
                                                        </button>
                                                        
                                                        <button v-if="(item.status_pendaftaran == 'menunggu_verifikasi' || item.status_pendaftaran == 'lolos_verifikasi')" :disabled="is_download_bukti" v-on:click="downloadBuktiPendaftaraan(item)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-primary btn-block">
                                                            <span>{{ is_download_bukti ? 'Loading...':'Bukti Pendaftaran' }}</span>
                                                            <span class="ml-auto">🖨️</span>
                                                        </button>

                                                        <button v-if="item.status_pendaftaran == 'lolos_verifikasi'" :disabled="is_download_bukti_verifikasi" v-on:click="downloadBuktiLolosVerifikasi(item)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-primary btn-block">
                                                            <span>{{ is_download_bukti_verifikasi ? 'Loading...':'Bukti Lolos Verifikasi' }}</span>
                                                            <span class="ml-auto">🖨️</span>
                                                        </button>

                                                        <hr />
                                                        <button v-on:click="deleteData(item.users_id, item.no_pendaftaran, item.fullname)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-danger btn-block" v-if="!is_download && (item.status_pendaftaran == 'belum_melengkapi' || item.status_pendaftaran == 'gagal_verifikasi')">
                                                            <span>Hapus Data Peserta</span>
                                                            <span class="ml-auto">🗑️</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>


                                    <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                    </nav>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modal_form_status" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ubah Status Pendaftaran</div>
                            <div>
                                <button id="btnCloseModalStatus" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nama Peserta</div>
                            <div class="font-weight-600">{{ form.fullname }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nomor Pendaftaran</div>
                            <div class="font-weight-600">{{ form.no_pendaftaran }}</div>
                        </div>

                        <div class="d-flex align-items-center pb-3 row">
                            <div class="col-md-12">
                                <div class="mt-1">
                                    <label>Pilih Status</label>
                                    <select class="form-control" v-model="form.status_pendaftaran">
                                        <option value="belum_melengkapi">Belum Melengkapi Data</option>
                                        <option value="menunggu_verifikasi">Menunggu Verifikasi Data</option>
                                        <option value="lolos_verifikasi">Lolos Verifikasi</option>
                                        <option value="gagal_verifikasi">Gagal Verifikasi (Ditolak)</option>
                                    </select>
                                </div>

                                <div class="d-none">
                                    <div class="mt-3" v-if="form.status_pendaftaran == 'gagal_verifikasi'">
                                        <label>Berikan Keterangan Gagal Verifikasi</label>
                                        <textarea class="form-control" rows="3" v-model="form.pesan_gagal" placeholder="Tuliskan alasan mengapa verifikasi gagal (misal: data tidak lengkap, berkas tidak valid, dsb.)"></textarea>
                                    </div>

                                    <div class="mt-3" v-if="form.status_pendaftaran == 'gagal_verifikasi'">
                                        <label>Pilih mana kelengkapan yang harus diperbaiki</label>
                                        <div>
                                            <input type="checkbox" id="step_1" v-model="form.step_gagal" value="1">
                                            <label for="step_1" class="ml-1">Data Diri / Identitas Siswa</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="step_2" v-model="form.step_gagal" value="2">
                                            <label for="step_2" class="ml-1">Data Orang Tua / Wali</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="step_3" v-model="form.step_gagal" value="3">
                                            <label for="step_3" class="ml-1">Data Sekolah Asal</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="step_4" v-model="form.step_gagal" value="4">
                                            <label for="step_4" class="ml-1">Peminatan & Data Nilai Rapor</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" id="step_5" v-model="form.step_gagal" value="5">
                                            <label for="step_5" class="ml-1">Upload Berkas (Ijazah/SKHUN/Pas Foto/Dll)</label>
                                        </div>
                                    </div>
                                </div>


                                <hr/>
                                <div class="mt-3">
                                    <button v-on:click="saveNewStatus()" type="button" class="btn btn-primary btn-block"><i class="fas fa-save"></i> Simpan Data</button>
                                </div>
                            </div>                        
                        </div>
                    </div>

                </div> 
            </div>
        </div>

        <div class="modal" id="modal_identitas" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Data Diri / Identitas Siswa</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nama Lengkap</div>
                            <div class="font-weight-600">{{ this.data_identitas.nama_lengkap }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">NISN</div>
                            <div class="font-weight-600">{{ this.data_identitas.nisn }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">No KK</div>
                            <div class="font-weight-600">{{ this.data_identitas.no_kk }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">NIK</div>
                            <div class="font-weight-600">{{ this.data_identitas.nik }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">KIP</div>
                            <div class="font-weight-600">{{ this.data_identitas.kip }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Tempat, Tanggal Lahir</div>
                            <div class="font-weight-600">{{ this.data_identitas.tempat_lahir }}, {{ this.data_identitas.tanggal_lahir }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Jenis Kelamin</div>
                            <div class="font-weight-600">{{ this.data_identitas.jenis_kelamin }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Agama</div>
                            <div class="font-weight-600">{{ this.data_identitas.agama }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Jumlah Saudara</div>
                            <div class="font-weight-600">{{ this.data_identitas.jumlah_saudara }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Anak Ke</div>
                            <div class="font-weight-600">{{ this.data_identitas.anak_ke }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Hobi</div>
                            <div class="font-weight-600">{{ this.data_identitas.hobi }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Cita-Cita</div>
                            <div class="font-weight-600">{{ this.data_identitas.cita_cita }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">No Handphone</div>
                            <div class="font-weight-600">{{ this.data_identitas.no_handphone }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Email</div>
                            <div class="font-weight-600">{{ this.data_identitas.email }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Yang Membiayai Sekolah</div>
                            <div class="font-weight-600">{{ this.data_identitas.yang_membiayai_sekolah }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Kebutuhan Disabilitas</div>
                            <div class="font-weight-600">{{ this.data_identitas.kebutuhan_disabilitas }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Kebutuhan Khusus</div>
                            <div class="font-weight-600">{{ this.data_identitas.kebutuhan_khusus }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Imunisasi</div>
                            <div class="font-weight-600">{{ this.data_identitas.imunisasi }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Status Tempat Tinggal</div>
                            <div class="font-weight-600">{{ this.data_identitas.status_tempat_tinggal }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Jarak Tinggal Madrasah</div>
                            <div class="font-weight-600">{{ this.data_identitas.jarak_tinggal_madrasah }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Waktu Tempuh</div>
                            <div class="font-weight-600">{{ this.data_identitas.waktu_tempuh }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2">
                            <div class="mr-3">Alamat</div>
                            <div class="font-weight-600">{{ this.data_identitas.alamat }}</div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>

        <div class="modal" id="modal_ortu" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-users"></i> Data Orang Tua/Wali</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <h6>A. Data Ayah Kandung</h6>
                        <hr/>
                        <div style="margin-left: 20px;">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_nama_lengkap }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">NIK</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_nik }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tempat Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_tempat_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_tanggal_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_status }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pendidikan Terakhir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_pendidikan_terakhir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pekerjaan Utama</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_pekerjaan_utama }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">No. Handphone</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_no_handphone }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Penghasilan Perbulan</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_penghasilan_perbulan }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status Tempat Tinggal</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_status_tempat_tinggal }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Alamat</div>
                                <div class="font-weight-600">{{ this.data_ortu.ayah_alamat }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="d-block px-3 pt-3 pb-0">
                        <h6>B. Data Ibu Kandung</h6>
                        <hr/>
                        <div style="margin-left: 20px;">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_nama_lengkap }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">NIK</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_nik }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tempat Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_tempat_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_tanggal_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_status }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pendidikan Terakhir</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_pendidikan_terakhir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pekerjaan Utama</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_pekerjaan_utama }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">No. Handphone</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_no_handphone }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Penghasilan Perbulan</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_penghasilan_perbulan }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status Tempat Tinggal</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_status_tempat_tinggal }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Alamat</div>
                                <div class="font-weight-600">{{ this.data_ortu.ibu_alamat }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="d-block px-3 pt-3 pb-0">
                        <h6>C. Data Wali</h6>
                        <hr/>
                        <div style="margin-left: 20px;">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_nama_lengkap }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">NIK</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_nik }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tempat Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_tempat_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tanggal Lahir</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_tanggal_lahir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_status }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pendidikan Terakhir</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_pendidikan_terakhir }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Pekerjaan Utama</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_pekerjaan_utama }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">No. Handphone</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_no_handphone }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Penghasilan Perbulan</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_penghasilan_perbulan }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Status Tempat Tinggal</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_status_tempat_tinggal }}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Alamat</div>
                                <div class="font-weight-600">{{ this.data_ortu.wali_alamat }}</div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div class="modal" id="modal_sekolah" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-school"></i> Data Sekolah Asal</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">NPSN</div>
                            <div class="font-weight-600">{{ this.data_sekolah.npsn }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Sekolah Asal SMP/MTs</div>
                            <div class="font-weight-600">{{ this.data_sekolah.sltp }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                            <div class="mr-3">Alamat Sekolah Asal SMP/MTs</div>
                            <div class="font-weight-600">{{ this.data_sekolah.alamat_sltp }}</div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>

        <div class="modal" id="modal_rapor" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-bars"></i> Peminatan & Data Nilai Rapor</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Peminatan / Kelas yang dipilih</div>
                            <div class="font-weight-600">{{ this.data_rapor.peminatan }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Nilai Rata - Rata Rapor</div>
                            <div class="font-weight-600">{{ this.data_rapor.rata_rata_nilai }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                            <div class="mr-3">Tabel Nilai Rapor</div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2">
                            <div class="table-responsive">
                                <table class="table table-bordered w-100">
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>IPA</td>
                                            <td>IPS</td>
                                            <td>MATEMATIKA</td>
                                            <td>INGGRIS</td>
                                            <td>INDONESIA</td>
                                        </tr>
                                    </thead>
                                    <tbody id="nilai_rapor">
                                        <tr v-for="(item, index) in this.data_rapor.nilai_rapor" :key="index">
                                            <td>Semester {{ index + 1 }}</td>
                                            <td><input :disabled="is_disabled_form_rapor == 1" type="text" class="form-control" v-model.number="item.ipa" @input="hitungRataRata"></td>
                                            <td><input :disabled="is_disabled_form_rapor == 1" type="text" class="form-control" v-model.number="item.ips" @input="hitungRataRata"></td>
                                            <td><input :disabled="is_disabled_form_rapor == 1" type="text" class="form-control" v-model.number="item.matematika" @input="hitungRataRata"></td>
                                            <td><input :disabled="is_disabled_form_rapor == 1" type="text" class="form-control" v-model.number="item.inggris" @input="hitungRataRata"></td>
                                            <td><input :disabled="is_disabled_form_rapor == 1" type="text" class="form-control" v-model.number="item.indonesia" @input="hitungRataRata"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <button style="margin-top:-20px" v-if="is_disabled_form_rapor" v-on:click="is_disabled_form_rapor = 0" type="button" class="btn btn-primary btn-sm rounded btn-block mt-0 mb-3"><i class="fa fa-edit"></i> Ubah Nilai</button>                        
                        <button style="margin-top:-20px" v-if="!is_disabled_form_rapor" v-on:click="saveNilaiRapor()" type="button" class="btn btn-outline-primary btn-sm rounded btn-block mt-0 mb-3"><i class="fa fa-save"></i> Simpan Nilai</button>                        
                    </div>
                </div> 
            </div>
        </div>

        <div class="modal" id="modal_berkas" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-upload"></i> Upload Berkas (Ijazah/SKHUN/Pas Foto/Dll)</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>                    

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Peminatan / Kelas yang dipilih</div>
                            <div class="font-weight-600">{{ this.data_berkas ? this.data_berkas.kelas : 'Tidak Ada' }}</div>
                        </div>
                    </div>

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Pas Foto:</strong></div>
                            <div>
                                <div v-if="data_berkas.pas_foto">
                                    <img :src="data_berkas.pas_foto" alt="Pas Foto" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;">
                                    <a :href="data_berkas.pas_foto" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>SKHUN:</strong></div>
                            <div>
                                <div v-if="data_berkas.skhun">
                                    <!-- <img :src="data_berkas.skhun" alt="SKHUN" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.skhun" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Ijazah:</strong></div>
                            <div>
                                <div v-if="data_berkas.ijazah">
                                    <!-- <img :src="data_berkas.ijazah" alt="Ijazah" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.ijazah" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Surat Kelulusan:</strong></div>
                            <div>
                                <div v-if="data_berkas.surat_kelulusan">
                                    <!-- <img :src="data_berkas.surat_kelulusan" alt="Surat Kelulusan" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.surat_kelulusan" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Kartu Keluarga:</strong></div>
                            <div>
                                <div v-if="data_berkas.kartu_keluarga">
                                    <!-- <img :src="data_berkas.kartu_keluarga" alt="Kartu Keluarga" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.kartu_keluarga" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Akta Kelahiran:</strong></div>
                            <div>
                                <div v-if="data_berkas.akta_kelahiran">
                                    <!-- <img :src="data_berkas.akta_kelahiran" alt="Akta Kelahiran" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.akta_kelahiran" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>KIP:</strong></div>
                            <div>
                                <div v-if="data_berkas.kip">
                                    <!-- <img :src="data_berkas.kip" alt="KIP" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.kip" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>

                        <div class="pb-2 mb-3 border-bottom">
                            <div><strong>Piagam Penghargaan:</strong></div>
                            <div>
                                <div v-if="data_berkas.piagam_penghargaan">
                                    <!-- <img :src="data_berkas.piagam_penghargaan" alt="Piagam Penghargaan" style="max-width: 200px; height: auto; border: 1px solid #ddd; padding: 5px;"> -->
                                    <a :href="data_berkas.piagam_penghargaan" download class="d-block text-primary mt-2">Download</a>
                                </div>
                                <span v-else>Tidak Ada</span>
                            </div>
                        </div>
                    </div>

                </div> 
            </div>
        </div>
        
        <!-- Modal -->
        <div class="modal" id="modalNewPasswordAdmin" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-edit"></i> Ganti Password</div>
                            <div>
                                <button id="btnCloseModalPassword" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    

                    <form v-on:submit.prevent="onSubmitPassword">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="mt-1">
                                        <label>Password Baru</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Masukan Password Baru..." v-model="form_password.new_password">
                                    </div>

                                    <div class="mt-3">
                                        <label>Ulangi Password Baru</label>
                                        <input type="password" autocomplete="off" class="form-control" aria-describedby="emailHelp" placeholder="Ulangi Password Baru..." v-model="form_password.retype_new_password">
                                    </div>

                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fas fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan Perubahan' }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>        
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'MembersPpdb',
        metaInfo: {
            title: 'Detail Jadwal Kelas',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                is_download         : false,
                is_download_bukti   : false,
                is_download_bukti_verifikasi   : false,
                is_disabled_form_rapor : true,
                waiting         : false,
                emptyState      : false,
                dataFetch       : false,
                dataFetchInfo   : true,
                dataList        : [],
                dataInfo        : [],
                status          : "",
                keywords        : "",
                data_identitas  : [],
                data_ortu       : [],
                data_sekolah    : [],
                data_rapor      : [],
                data_berkas     : [],
                form            : {
                    status_pendaftaran : '',
                    no_pendaftaran : '',
                    fullname       : '',
                    id          : '',
                    ppdb_formulir_id : '',
                    users_id    : '',
                    pesan_gagal : '',
                    step_gagal  : [],
                    rata_rata_nilai : ''
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 50,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form_password : {
                    id                  : '',
                    new_password        : '',
                    retype_new_password : '',
                },
                submitted : false
            }
        },
        created() {
            this.getInfo();
        },
        methods: {
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getInfo: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/detail';
                this.dataFetchInfo  = true;                
                this.$http.get(uri,{
                    params : {
                        id  : this.$route.params.id,
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetchInfo  = false;                
                        this.dataInfo       = res.data.data;
                        this.getData();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                        });

                        this.goBack();
                    }
                });
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/list-members';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.id,
                        keywords  : this.keywords,
                        status    : this.status,
                        page      : this.pagination.currentPage,
                        limit     : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goBack: function() {
                this.$router.push({ name : 'admin_ppdb_formulir'});
            },
            openData: function(params, users_id='', ppdb_formulir_id='') {
                let uri = process.env.VUE_APP_APIHOST+'admin/ppdb/identitas-siswa';

                if(params == 'ortu') {
                    uri = process.env.VUE_APP_APIHOST+'admin/ppdb/orangtua-wali';
                }

                if(params == 'sekolah') {
                    uri = process.env.VUE_APP_APIHOST+'admin/ppdb/sekolah-asal';
                }

                if(params == 'rapor') {
                    uri = process.env.VUE_APP_APIHOST+'admin/ppdb/peminatan-rapor';
                }

                if(params == 'berkas') {
                    uri = process.env.VUE_APP_APIHOST+'admin/ppdb/berkas-siswa';
                }

                this.$http.get(uri,{
                    params : {
                        users_id  : users_id,
                    }
                }).then(res => {
                    if(params == 'identitas') {
                        this.data_identitas = res.data.data;
                    }
                    if(params == 'ortu') {
                        this.data_ortu = res.data.data;
                    }
                    if(params == 'sekolah') {
                        this.data_sekolah = res.data.data;
                    }
                    if(params == 'rapor') {
                        this.data_rapor = res.data.data;
                        this.data_rapor.ppdb_formulir_id = ppdb_formulir_id
                    }
                    if(params == 'berkas') {
                        this.data_berkas = res.data.data;
                    }
                });
            },
            setFormStatus: function(status_pendaftaran, no_pendaftaran, fullname, id, ppdb_formulir_id, users_id) {
                this.form.status_pendaftaran = status_pendaftaran;
                this.form.no_pendaftaran = no_pendaftaran;
                this.form.fullname = fullname;
                this.form.id = id;
                this.form.ppdb_formulir_id = ppdb_formulir_id;
                this.form.users_id = users_id;
            },
            saveNewStatus: function() {
                // if (this.form.status_pendaftaran === 'gagal_verifikasi' && (!this.form.step_gagal || this.form.step_gagal.length === 0)) {
                //     this.$swal({
                //         icon: 'warning',
                //         title: 'Ups!',
                //         text: 'Mohon isi mana kelengkapan yang harus diperbaiki oleh peserta',
                //     });
                //     return;
                // }

                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin mengubah status pendaftaran peserta ini?',
                    confirmButtonText: 'Iya, Simpan Perubahan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {

                        let formData = new FormData();
                        formData.append('id', this.form.id);
                        formData.append('status_pendaftaran', this.form.status_pendaftaran);
                        formData.append('pesan_gagal', this.form.pesan_gagal);
                        formData.append('ppdb_formulir_id', this.form.ppdb_formulir_id);
                        formData.append('users_id', this.form.users_id);

                        if (this.form.status_pendaftaran === 'gagal_verifikasi') {
                            formData.append('step_gagal', JSON.stringify(this.form.step_gagal));
                        }

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/save-status', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.form.step_gagal = [];
                                            document.getElementById("btnCloseModalStatus").click();
                                            this.getData();
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                                this.waiting = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });                        
                    }
                });
            },
            downloadHasilFormulir: function(users_id="", fullname="", no_pendaftaran="") {
                this.is_download = true;
                let title = fullname+" - "+no_pendaftaran;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/cetak-hasil', {
                    params : {
                        id : this.$route.params.id,
                        users_id : users_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.is_download = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Hasil Formulir Pendaftaran - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            deleteData: function (id_users, no_pendaftaran, fullname) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                html: 'Apakah kamu yakin ingin menghapus data peserta <b>'+fullname+'</b> dengan nomor pendaftaran: <b>'+no_pendaftaran+'</b>?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/delete-users', {
                        id_users: id_users
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            setId: function(id) {
                this.form_password.id = id;
            },
            onSubmitPassword: function () {
                this.waiting    = true;
                this.submitted  = true;

                if (this.form_password.id == '' || this.form_password.new_password == '' || this.form_password.retype_new_password == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/members/change_password', {
                        id                  : this.form_password.id,
                        new_password        : this.form_password.new_password,
                        retype_new_password : this.form_password.retype_new_password,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModalPassword").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            this.waiting = false;
                        }
                    })
                }
            },
            downloadBuktiPendaftaraan: function(item) {
                let title = item.no_pendaftaran;
                this.is_download_bukti = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/formulir-send/cetak', {
                    params : {
                        id : item.ppdb_formulir_id,
                        users_id : item.users_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.is_download_bukti = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Pendaftaraan - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            downloadBuktiLolosVerifikasi: function(item) {
                let title = item.no_pendaftaran;
                this.is_download_bukti_verifikasi = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/formulir/cetak-verifikasi', {
                    params : {
                        id : item.ppdb_formulir_id,
                        users_id : item.users_id
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.is_download_bukti_verifikasi = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Lolos Verifikasi - '+title}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            hitungRataRata() {
                let totalMapel = {
                    ipa: 0,
                    ips: 0,
                    matematika: 0,
                    inggris: 0,
                    indonesia: 0
                };

                let jumlahSemester = 5;

                // Ambil nilai dari input
                let inputs = document.querySelectorAll("#nilai_rapor tr");

                inputs.forEach(row => {
                    let nilai = row.querySelectorAll("td input");
                    totalMapel.ipa += parseFloat(nilai[0].value) || 0;
                    totalMapel.ips += parseFloat(nilai[1].value) || 0;
                    totalMapel.matematika += parseFloat(nilai[2].value) || 0;
                    totalMapel.inggris += parseFloat(nilai[3].value) || 0;
                    totalMapel.indonesia += parseFloat(nilai[4].value) || 0;
                });
                
                // Hitung rata-rata tiap mata pelajaran
                let rataPerMapel = Object.keys(totalMapel).map(mapel => totalMapel[mapel] / jumlahSemester);

                // Hitung rata-rata keseluruhan
                let rataKeseluruhan = rataPerMapel.reduce((a, b) => a + b, 0) / rataPerMapel.length;

                // Masukkan ke v-model
                this.data_rapor.rata_rata_nilai = rataKeseluruhan.toFixed(2);
            },
            saveNilaiRapor() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah anda yakin ingin menyimpan perubahan nilai?',
                    confirmButtonText: 'Simpan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        let formData = new FormData();

                        formData.append('id', this.data_rapor.id);
                        formData.append('peminatan', this.data_rapor.peminatan);
                        formData.append('rata_rata_nilai', this.data_rapor.rata_rata_nilai);
                        formData.append('nilai_rapor', JSON.stringify(this.data_rapor.nilai_rapor));
                        formData.append('ppdb_formulir_id',  this.data_rapor.ppdb_formulir_id);
                        formData.append('users_id',  this.data_rapor.users_id);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/peminatan-rapor/save', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                        this.is_disabled_form_rapor = 1;
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Silakan cek koneksi internet Anda.',
                            });
                            this.waiting = false;
                        });
                    }
                });
            }     
        }
    }
</script>
