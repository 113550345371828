<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Formulir Pendaftaran</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'admin_ppdb_formulir'}">Formulir Pendaftaran</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama Formulir</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kode Formulir</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.kode_pendaftaran" :class="{ 'is-invalid': submitted && $v.form.kode_pendaftaran.$error }">
                                        <small>Kode ini digunakan untuk penamaan nomor pendaftaran.</small>
                                    <div v-if="submitted && !$v.form.kode_pendaftaran.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tahun Ajaran</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.tahun_ajaran" :class="{ 'is-invalid': submitted && $v.form.tahun_ajaran.$error }">
                                    <div v-if="submitted && !$v.form.tahun_ajaran.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tanggal Mulai Pendaftaran</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.start_date" :class="{ 'is-invalid': submitted && $v.form.start_date.$error }">
                                    <div v-if="submitted && !$v.form.start_date.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tanggal Selesai Pendaftaran</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.end_date" :class="{ 'is-invalid': submitted && $v.form.end_date.$error }">
                                    <div v-if="submitted && !$v.form.end_date.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Catatan (<small>Catatan ini akan ditampilkan pada hasil cetak bukti pendaftaran</small>)</label>
                                    <ckeditor v-model="form.note" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Catatan (<small>Catatan ini akan ditampilkan pada hasil cetak lolos tahap verifikasi</small>)</label>
                                    <ckeditor v-model="form.note_verifikasi" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Catatan (<small>Catatan ini akan ditampilkan pada hasil cetak kelulusan</small>)</label>
                                    <ckeditor v-model="form.note_kelulusan" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Catatan (<small>Catatan ini akan ditampilkan pada menu pembiayaan</small>)</label>
                                    <ckeditor v-model="form.note_pembiayaan" :config="editorConfig"></ckeditor>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">URL QR code (<small>Untuk grup whatsapp/telegram/dll</small>)</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan url (contoh: http://bit.ly/grupwappdb)..." v-model="form.url_qrcode">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_qrcode">
                                    <label for="exampleInputEmail1">Preview qrcode</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_qrcode" data-lightbox="1" target="blank">
                                                <img :src="preview_qrcode" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover</label>
                                    <input type="file" class="form-control" name="cover" id="cover" @change="previewFiles" accept="cover/png, cover/gif, cover/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_cover">
                                    <label for="exampleInputEmail1">Preview cover</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_cover" data-lightbox="1" target="blank">
                                                <img :src="preview_cover" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'admin_ppdb_formulir'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'AdminPpdbFormulirForm',
        metaInfo: {
            title: 'Form Formulir Pendaftaran',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                preview_cover   : '',
                form : {
                    id                  : "",
                    title               : "",
                    kode_pendaftaran    : "",
                    tahun_ajaran        : "",
                    start_date          : "",
                    end_date            : "",
                    status              : "draft",
                    cover               : "",
                    note                : "",
                    note_verifikasi     : "",
                    note_kelulusan     : "",
                    note_pembiayaan     : "",
                    url_qrcode     : "",
                },
                editorConfig: {
                    toolbar :  [{
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Strike','Subscript','Superscript','-', 'RemoveFormat','SpecialChar','EqnEditor','PasteFromWord']
                        }, {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                        }, {
                        name: 'links',
                        items: ['Link', 'Unlink']
                        }, {
                        name: 'insert',
                        items: ['Image', 'EmbedSemantic', 'Table']
                        }, {
                        name: 'tools',
                        items: ['Maximize']
                    }],
                    extraPlugins:'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                    imageUploadUrl: process.env.VUE_APP_APIHOST+'uploader/images',
                    uploadUrl: process.env.VUE_APP_APIHOST+'uploader/files',
                    versionCheck: false                    
                },                
                submitted       : false,
                waiting         : false                
            }
        },
        validations: {
            form: {
                kode_pendaftaran: {
                    required
                },
                title: {
                    required
                },
                tahun_ajaran: {
                    required
                },
                start_date: {
                    required
                },
                end_date: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('title', this.form.title);
                    formData.append('kode_pendaftaran', this.form.kode_pendaftaran);
                    formData.append('tahun_ajaran', this.form.tahun_ajaran);
                    formData.append('start_date', this.form.start_date);
                    formData.append('end_date', this.form.end_date);
                    formData.append('note', this.form.note);
                    formData.append('note_verifikasi', this.form.note_verifikasi);
                    formData.append('note_kelulusan', this.form.note_kelulusan);
                    formData.append('note_pembiayaan', this.form.note_pembiayaan);
                    formData.append('url_qrcode', this.form.url_qrcode);
                    formData.append('status', this.form.status);
                    formData.append('cover', this.form.cover);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'admin_ppdb_formulir' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data;
                            
                            this.form           = getResponse;
                            this.form.cover     = getResponse.cover;
                            this.preview_cover  = getResponse.cover;
                            this.preview_qrcode  = getResponse.qrcode_image;

                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'admin_ppdb_formulir' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_cover = URL.createObjectURL(file);
                    this.form.cover = file;
                }
            }
        }
    }
</script>