<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a v-on:click="goBack()" href="javascript:void(0)"><i class="fa fa-arrow-left m-r-10"></i></a>
                                Peminatan & Data Nilai Rapor
                            </h5>
                        </div>
                    </div>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="row">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.id">

                                    <div class="form-group col-md-6">
                                        <label for="peminatan">Peminatan <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="peminatan"
                                            v-model="form.peminatan" :class="{ 'is-invalid': submitted && $v.form.peminatan.$error }">
                                            <option value="">Pilih Peminatan</option>
                                            <option v-for="(item, index) in dataDetail.peminatan" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.peminatan.required" class="invalid-feedback">Peminatan wajib diisi!</div>                                    
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="rata_rata_nilai">Rata - Rata Nilai Rapor <span class="text-danger">*</span></label>
                                        <input readonly :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="rata_rata_nilai"
                                            placeholder="Silahkan isi tabel perhitungan nilai rapor dibawah ini." v-model="form.rata_rata_nilai"
                                            :class="{ 'is-invalid': submitted && $v.form.rata_rata_nilai.$error }">
                                        <div v-if="submitted && !$v.form.rata_rata_nilai.required" class="invalid-feedback">Rata - Rata Nilai Rapor wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <small v-if="form.peminatan" style="text-align: justify;">
                                            <b>{{ form.peminatan }}</b>
                                            <span v-if="form.peminatan == 'Kelas Sains'" style="text-align:justify">
                                                <b> (Prestasi Akademik)</b> merupakan jalur PPDB MAN 1 BANYUMAS melalui seleksi akademik dan wawancara dengan menggunakan Computer Assesment Test (CAT), dapat diikuti oleh seluruh peserta didik yang terdaftar pada kelas reguler dan mempertimbangan nilai rapor serta prestasi akademik di SMP atau MTS asal.<br/>
                                                Kurikulum di Kelas Khusus Sains Menggunakan kurikulum modifikasi dan disesuaikan untuk membuat peserta didik dapat meningkatkan prestasi akademik dan dapat bersaing masuk perguruan tinggi negeri.
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Riset'" style="text-align:justify"> 
                                                <b> (Prestasi Mandiri 1)</b> merupakan jalur PPDB MAN 1 BANYUMAS melalui seleksi akademik dan wawancara dengan
                                                mempertimbangan nilai rapor dan motivasi belajar ke perguruan tinggi negeri.<br/>
                                                Kurikulum di Kelas Khusus Riset Menggunakan kurikulum modifikasi dan disesuaikan untuk membuat peserta didik dapat meningkatkan prestasi akademik dan dapat bersaing masuk perguruan tinggi negeri.
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Multimedia'" style="text-align:justify"> 
                                                <b> (Prestasi Mandiri 2)</b> merupakan jalur PPDB MAN 1 BANYUMAS melalui seleksi akademik dan wawancara dengan
                                                mempertimbangan nilai rapor dan motivasi belajar ke perguruan tinggi negeri.<br/>
                                                Kurikulum di Kelas Khusus Multimedia Menggunakan kurikulum modifikasi dan disesuaikan untuk membuat peserta didik dapat meningkatkan prestasi akademik dan dapat bersaing masuk perguruan tinggi negeri.
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Khusus Olahraga (KKO)'" style="text-align:justify">
                                                merupakan jalur PPDB MAN 1 BANYUMAS melalui seleksi prestasi olahraga dan bakat minat selain akademik dengan mempertimbangkan nilai rapor dan prestasi non akademik.<br/>
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Seni'" style="text-align:justify">
                                                merupakan jalur PPDB MAN 1 BANYUMAS melalui seleksi praktik seni (seni musik, seni tari, kaligrafi dll.) dan bakat minat selain akademik dengan mempertimbangkan nilai rapor dan prestasi non akademik.<br/>
                                                Menerapkan kurikulum modifikasi yang mengutamakan prestasi peserta didik.
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Reguler'" style="text-align:justify">
                                                merupakan jalur PPDB MAN 1 BANYUMAS dengan mempertimbangkan nilai rapor, wawancara dan pemetaan BTA.<br/>
                                                Kurikulum di Kelas Reguler menggunakan kurikulum umum dan kurikulum madrasah.
                                            </span>
                                            <span v-if="form.peminatan == 'Kelas Tahfidz'" style="text-align:justify">
                                                merupakan jalur PPDB MAN 1 BANYUMAS dengan prestasi akademik dan motivasi belajar AlQur'an<br/>
                                                Kurikulum di Kelas Tahfidz merupakan kurikulum modifikasi dan disesuaikan untuk membuat peserta didik dapat meningkatkan menghafal AlQur'an dan melanjutkan ke perguruan tinggi negeri.
                                            </span>
                                        </small>                                        
                                        <hr/>
                                        <label for="peminatan">Tabel Nilai Rapor</label>
                                        <div class="table-responsive">
                                            <table class="table table-bordered w-100">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>IPA</td>
                                                        <td>IPS</td>
                                                        <td>MATEMATIKA</td>
                                                        <td>INGGRIS</td>
                                                        <td>INDONESIA</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in nilaiRapor" :key="index">
                                                        <td>Semester {{ index + 1 }}</td>
                                                        <td><input type="text" class="form-control" v-model.number="item.ipa" @input="hitungRataRata"></td>
                                                        <td><input type="text" class="form-control" v-model.number="item.ips" @input="hitungRataRata"></td>
                                                        <td><input type="text" class="form-control" v-model.number="item.matematika" @input="hitungRataRata"></td>
                                                        <td><input type="text" class="form-control" v-model.number="item.inggris" @input="hitungRataRata"></td>
                                                        <td><input type="text" class="form-control" v-model.number="item.indonesia" @input="hitungRataRata"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <small>Keterangan: Tanda (<span class="text-danger">*</span>) Wajib Diisi</small>
                        </div>

                        <div class="col-md-4">
                            <div v-if="dataDetail.member.terkirim == 1" class="alert alert-info text-center" role="alert">
                                <b>Keterangan:</b><br/> Kamu tidak bisa melakukan perubahan ketika data sudah terkirim/sedang dalam proses verifikasi.
                            </div>

                            <button
                                :disabled="dataDetail.member.terkirim == 1 ? true:false"
                                type="submit"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span><i class="fa fa-save"></i> Simpan Data</span>
                                <span class="ml-auto">💾</span>
                            </button>
                            <button
                                v-on:click="alertBack()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Kembali</span>
                            </button>
                            <hr/>
                            <div class="alert alert-warning" role="alert">
                                <b>Catatan:</b>
                                <hr/>
                                <ol style="margin-left: -10px !important;">
                                    <li>Kelas Reguler memiliki kesempatan untuk diseleksi kelas Sains dan Reguler.</li>
                                    <li>Kelas KKO dst redaksi kata dari panitia.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'PeminatanRapor',
        metaInfo: {
            title: 'Peminatan & Data Nilai Rapor',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                form        : {},
                submitted   : false,
                waiting     : false,
                nilaiRapor: [
                    { ipa: null, ips: null, matematika: null, inggris: null, indonesia: null },
                    { ipa: null, ips: null, matematika: null, inggris: null, indonesia: null },
                    { ipa: null, ips: null, matematika: null, inggris: null, indonesia: null },
                    { ipa: null, ips: null, matematika: null, inggris: null, indonesia: null },
                    { ipa: null, ips: null, matematika: null, inggris: null, indonesia: null }
                ]
            }
        },
        created() {
            this.getData()
        },
        validations: {
            form: {
                peminatan: {
                    required
                },
                rata_rata_nilai: {
                    required
                }
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.ppdb_formulir_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data
                        this.getIdentitas();
                    } else {
                        this.dataFetch  = false;
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            getIdentitas: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/peminatan-rapor';
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.form = res.data.data
                        if(this.form.nilai_rapor) {
                            this.nilaiRapor = this.form.nilai_rapor;
                        }
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : this.$route.params.ppdb_formulir_id
                    }
                });
            },
            alertBack: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin kembali ke halaman sebelumnya?',
                    confirmButtonText: 'Iya, Kembali',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.goBack()
                    }
                });                
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // Validasi form
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                }

                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('peminatan', this.form.peminatan);
                formData.append('rata_rata_nilai', this.form.rata_rata_nilai);
                formData.append('nilai_rapor', JSON.stringify(this.nilaiRapor));
                formData.append('ppdb_formulir_id',  this.$route.params.ppdb_formulir_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/peminatan-rapor/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Silakan cek koneksi internet Anda.',
                    });
                    this.waiting = false;
                });
            },
            hitungRataRata() {
                let totalMapel = {
                    ipa: 0,
                    ips: 0,
                    matematika: 0,
                    inggris: 0,
                    indonesia: 0
                };

                let jumlahSemester = 5;

                // Ambil nilai dari input
                let inputs = document.querySelectorAll("tbody tr");

                inputs.forEach(row => {
                    let nilai = row.querySelectorAll("td input");
                    totalMapel.ipa += parseFloat(nilai[0].value) || 0;
                    totalMapel.ips += parseFloat(nilai[1].value) || 0;
                    totalMapel.matematika += parseFloat(nilai[2].value) || 0;
                    totalMapel.inggris += parseFloat(nilai[3].value) || 0;
                    totalMapel.indonesia += parseFloat(nilai[4].value) || 0;
                });

                // Hitung rata-rata tiap mata pelajaran
                let rataPerMapel = Object.keys(totalMapel).map(mapel => totalMapel[mapel] / jumlahSemester);

                // Hitung rata-rata keseluruhan
                let rataKeseluruhan = rataPerMapel.reduce((a, b) => a + b, 0) / rataPerMapel.length;

                // Masukkan ke v-model
                this.form.rata_rata_nilai = rataKeseluruhan.toFixed(2);
            }
        }
    }
</script>