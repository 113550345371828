<template>
    <div>
        <div v-if="dataFetch">
            <p>Sedang memuat data...</p>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Setup Landing Page</h5>
                        </div>
                    </div>
                    <div v-if="!dataFetch" class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Title Landing Page</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data" v-model="form.title_ppdb">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Alamat Sekolah</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data" v-model="form.alamat_sekolah">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Whatsapp</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data" v-model="form.ppdb_whatsapp">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Masukan data" v-model="form.ppdb_email">
                                </div>
                                

                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Jadwal</label>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td>Gelombang Ke</td>
                                                <td>Periode</td>
                                                <td>Biaya Pendaftaran</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in form.ppdb_biaya_dan_jadwal" :key="index">
                                                <td><input type="text" class="form-control" v-model="item.gelombang"></td>
                                                <td><input type="text" class="form-control" v-model="item.periode"></td>
                                                <td><input type="text" class="form-control" v-model="item.biaya"></td>
                                                <td><button type="button" class="btn btn-danger btn-sm rounded" @click="removeJadwal(index)">Hapus</button></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3"><button type="button" class="btn btn-success btn-sm rounded" @click="addJadwal">Tambah Jadwal</button>                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover Persyaratan</label>
                                    <input type="file" class="form-control" name="ppdb_persyaratan" id="ppdb_persyaratan" @change="previewFiles" accept="image/png, image/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_ppdb_persyaratan">
                                    <label for="exampleInputEmail1">Preview Cover Persyaratan</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_ppdb_persyaratan" data-lightbox="1" target="blank">
                                                <img :src="preview_ppdb_persyaratan" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover Informasi</label>
                                    <input type="file" class="form-control" name="ppdb_informasi" id="ppdb_informasi" @change="previewFilesInformasi" accept="image/png, image/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_ppdb_informasi">
                                    <label for="exampleInputEmail1">Preview Cover Informasi</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_ppdb_informasi" data-lightbox="1" target="blank">
                                                <img :src="preview_ppdb_informasi" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn rounded btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'SK',
        metaInfo: {
            title: 'Setup Landing Page - PPDB',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                preview_ppdb_persyaratan   : '',
                preview_ppdb_informasi      : '',
                form : {
                    id                      : "",
                    title_ppdb              : "",
                    ppdb_persyaratan        : "",
                    ppdb_informasi          : "",
                    alamat_sekolah          : "",
                    ppdb_whatsapp           : "",
                    ppdb_email              : "",
                    ppdb_biaya_dan_jadwal   : "",
                },
                submitted   : false,
                waiting     : false,
                dataFetch   : true
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('title_ppdb', this.form.title_ppdb);
                formData.append('ppdb_persyaratan', this.form.ppdb_persyaratan);
                formData.append('ppdb_informasi', this.form.ppdb_informasi);
                formData.append('alamat_sekolah', this.form.alamat_sekolah);
                formData.append('ppdb_whatsapp', this.form.ppdb_whatsapp);
                formData.append('ppdb_email', this.form.ppdb_email);
                formData.append('ppdb_biaya_dan_jadwal', JSON.stringify(this.form.ppdb_biaya_dan_jadwal));

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb-formulir/save-landing', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/ppdb-formulir/check-school';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetch  = false;
                        let getResponse = res.data.data;
                        this.form       = getResponse;
                        this.form.cover                 = getResponse.ppdb_informasi;
                        this.preview_ppdb_persyaratan   = getResponse.ppdb_persyaratan;
                        this.preview_ppdb_informasi     = getResponse.ppdb_informasi;
                    }
                });
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_ppdb_persyaratan = URL.createObjectURL(file);
                    this.form.ppdb_persyaratan = file;
                }
            },
            previewFilesInformasi(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_ppdb_informasi = URL.createObjectURL(file);
                    this.form.ppdb_informasi = file;
                }
            },
            addJadwal() {
                this.form.ppdb_biaya_dan_jadwal.push({
                    gelombang: "",
                    periode: "",
                    biaya: ""
                });
            },
            removeJadwal(index) {
                this.form.ppdb_biaya_dan_jadwal.splice(index, 1);
            },            
        }
    }
</script>