<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><router-link :to="{name : 'member_paket_pppk'}"><i class="fa fa-arrow-left m-r-10"></i></router-link>{{ dataList.name }}</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Paket</div>
                                <div class="font-weight-600">{{dataList.name}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Masa Aktif</div>
                                <div class="font-weight-600">{{dataList.active_days}} Hari</div>
                            </div>

                            <div v-if="dataList.list_tryout.length > 0" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Tryout yang didapatkan</div>
                                <div class="font-weight-600"><a href="javascript:void(0)" v-on:click="showTryout = !showTryout">
                                    {{!showTryout ? 'Lihat':'Tutup'}}
                                </a></div>
                            </div>

                            <transition name="fade">
                                <div class="row" v-if="showTryout">
                                    <div class="col-md-6" v-for="(item, index) in dataList.list_tryout" :key="index">
                                        <div class="alert alert-box" role="alert">
                                            <table style="width:100%">
                                                <tr>
                                                    <td style="vertical-align:middle;width:10%;color:#dcdcdc !important"><i class="fa fa-lock"></i></td>
                                                    <td>
                                                        {{item.title}}
                                                        <br/>{{item.total_questions}} Soal
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>                                
                                    </div>
                                </div>
                            </transition>

                            <div v-if="dataList.list_exercises.length > 0" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Latihan yang didapatkan</div>
                                <div class="font-weight-600"><a href="javascript:void(0)" v-on:click="showExercises = !showExercises">
                                    {{!showExercises ? 'Lihat':'Tutup'}}
                                </a></div>
                            </div>

                            <transition name="fade">
                                <div class="row" v-if="showExercises">
                                    <div class="col-md-6" v-for="(item, index) in dataList.list_exercises" :key="index">
                                        <div class="alert alert-box" role="alert">
                                            <table style="width:100%">
                                                <tr>
                                                    <td style="vertical-align:middle;width:10%;color:#dcdcdc !important"><i class="fa fa-lock"></i></td>
                                                    <td>
                                                        {{item.title}}
                                                        <br/>{{item.total_questions}} Soal
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>                                
                                    </div>
                                </div>
                            </transition>

                            <div v-if="dataList.list_theory.length > 0" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Materi yang didapatkan</div>
                                <div class="font-weight-600"><a href="javascript:void(0)" v-on:click="showTheory = !showTheory">
                                    {{!showTheory ? 'Lihat':'Tutup'}}
                                </a></div>
                            </div>

                            <transition name="fade">
                                <div class="row" v-if="showTheory">
                                    <div class="col-md-6" v-for="(item, index) in dataList.list_theory" :key="index">
                                        <div class="alert alert-box" role="alert">
                                            <table style="width:100%">
                                                <tr>
                                                    <td style="vertical-align:middle;width:10%;color:#dcdcdc !important"><i class="fa fa-lock"></i></td>
                                                    <td>
                                                        {{item.title}}
                                                        <br/>{{item.type == 'youtube' ? 'Berupa Video':'Berupa File / Text'}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>                                
                                    </div>
                                </div>
                            </transition>

                            <div class="row mb-3">
                                <div class="col-md-6 mt-2" v-for="(item, index) in dataList.description_other" :key="index">
                                    <i class="fa fa-check" style="color:#6fd96f !important;margin-right:5px"></i> {{item}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" v-if="!dataSubscriber.id">
                        <div class="alert alert-info  text-center" role="alert">
                            <b v-if="dataList.text_price_out" style="color:red !important"><s>{{dataList.text_price_out}}</s></b>
                            <h3 style="color:green !important">{{dataList.text_price}}</h3>
                            Dapatkan <b>Potongan harga</b> dengan<br/>Kode Promo / Referral.
                        </div>
                        <div>
                            <button type="button" class="btn btn-primary rounded btn-block m-t-10" data-toggle="modal" data-target="#modalBuyPackage" v-on:click="buyPackage"><i class="fa fa-shopping-cart"></i> Beli Paket</button>
                        </div>
                    </div>

                    <div class="col-md-4" v-if="dataSubscriber.id">
                        <div class="alert alert-info  text-center" role="alert">
                            Kamu <b>Sedang berlangganan paket</b> ini<br/>
                            Berlaku hingga <br/><b>{{dataSubscriber.expired_text}}</b>
                        </div>
                        <div>
                            <router-link class="btn btn-primary rounded btn-block m-t-10" :to="{name : 'home'}"><i class="fa fa-bars"></i> Lihat Paket Saya</router-link>
                        </div>
                    </div>
                </div>

            </div>        
        </div>

        <!-- Modal -->
        <div class="modal" id="modalBuyPackage" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-shopping-cart"></i> Beli Paket</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3">
                        <div class="d-flex align-items-center pb-3 mb-3 border-bottom">
                            <div>
                                <div style="font-size:18px" class="d-block mb-1 text-sm">Nama Paket</div>
                                <div style="font-size:20px" class="d-block h6 font-weight-600 mb-1">{{dataList.name}}</div>
                                <div class="d-block h6 font-weight-600">Masa Aktif {{dataList.active_days}} Hari</div>
                            </div>
                        </div>
                        <div class="d-block mb-1">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Harga</div>
                                <div class="font-weight-600"><s v-if="dataList.text_price_out" class="text-muted">{{dataList.text_price_out}}</s> {{dataList.text_price}}</div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Potongan Harga</div>
                                <div class="font-weight-600">
                                    <span v-if="!form.discount_voucher">Rp. 0</span>
                                    <span style="color:#43ab43" v-if="form.discount_voucher">{{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Kode Referral / Voucher<br/>
                                <small>Gunakan Kode untuk mendapatkan potongan harga</small>
                                </div>
                                <div class="font-weight-600">
                                    <a v-if="!form.fix_voucher" v-on:click="showVoucher" href="javascript:void(0)">Masukan Kode</a>
                                    <span class="badge badge-info" v-if="form.fix_voucher" href="javascript:void(0)">{{form.fix_voucher}} - {{form.discount_voucher}}</span>
                                </div>
                            </div>
                            <div class="input-group mb-3 m-t-10" v-if="showInputVoucher">
                                <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Kode Referral / Voucher ..." v-model="form.voucher" v-on:keyup.enter="checkVoucher">
                                <div class="input-group-append">
                                    <button v-on:click="checkVoucher" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button">Gunakan Kode</button>
                                </div>
                            </div>

                            <h5>Total Harga : <span class="float-right"><s style="color:red" v-if="form.total_price_out">{{form.total_price_out}}</s> {{dataList.total_price}}</span></h5>
                            <button :disabled="processFetch" v-on:click="checkout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-shopping-cart"></i>
                                {{ processFetch ? 'Sedang membuat invoice ...' : 'Beli Paket' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailPaketPPPK',
    metaInfo: {
        title: 'Detail Paket PPPK',
        titleTemplate: '%s - Griya Belajar'
    },
    data() {
        return {
            showTryout          : false,
            showExercises       : false,
            showTheory          : false,
            processFetch        : false,
            dataFetch           : false,
            dataList            : {
                list_tryout : [],
                list_exercises : [],
                list_theory : []
            },
            dataSubscriber      : {
                id           : '',
                expired_text : ''
            },
            emptyState          : false,
            showInputVoucher    : false,
            form                : {
                id : '',
                name : '',
                text_price_out : '',
                text_price : '',
                active_days : '',
                voucher : '',
                price : 0,
                fix_voucher : '',
                discount_voucher : 0,
                total_price : 0,
                total_price_out : 0,
            },
            keywords:''
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function() {
            let uri         = process.env.VUE_APP_APIHOST+'member/package/detail';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    type    : 'pppk',
                    code    : this.$route.params.code
                }
            }).then(res => {
                this.emptyState = false;
                this.dataFetch  = false;                
                this.dataList   = res.data.data;

                if(this.dataList.length < 1) {
                    this.emptyState = true;
                }

                if(this.dataList.subscriber.id) {
                    this.dataSubscriber = this.dataList.subscriber;
                }
            });
        },
        showVoucher: function() {
            this.showInputVoucher = true;
        },
        buyPackage: function() {
            this.form.id                = this.dataList.id;
            this.form.name              = this.dataList.name;
            this.form.text_price_out    = this.dataList.text_price_out;
            this.form.text_price        = this.dataList.text_price;
            this.form.price             = this.dataList.price;
            this.form.total_price       = this.dataList.text_price;
            this.form.active_days       = this.dataList.active_days;
            this.form.fix_voucher            = '';
            this.form.discount_voucher       = 0;
            this.form.total_price_out        = 0;

        },
        checkout: function() {
            this.$swal({
                icon: 'info',
                title: 'Konfirmasi Pembayaran',
                text: 'Apakah kamu yakin ingin melakukan proses pembayaran ?',
                confirmButtonText: 'Yakin',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            }).then((result) => {
                if(result.value) {
                    this.processFetch = true;
                    let formData    = new FormData();
                    formData.append('category', 'pppk');
                    formData.append('voucher', this.form.fix_voucher);
                    formData.append('id', this.form.id);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/checkout', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        document.getElementById("closeModal").click();
                                        this.$router.push({
                                            name: 'invoice_detail',
                                            params: {
                                                invoice : response.data.data
                                            }
                                        });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.processFetch = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.processFetch = false;
                        return;
                    });
                }
            });
        },
        checkVoucher: function() {
            let formData    = new FormData();
            formData.append('category', 'pppk');
            formData.append('voucher', this.form.voucher);
            formData.append('id', this.form.id);
            this.$http.post(process.env.VUE_APP_APIHOST + 'member/package/check-voucher', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            html: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                let getResponse             = response.data.data;
                                let total_price             = getResponse.text_total_price;
                                let total_price_out         = getResponse.text_before_price;

                                this.form.fix_voucher       = this.form.voucher;
                                this.form.discount_voucher  = getResponse.text_discount;
                                this.form.total_price       = total_price;
                                this.form.total_price_out   = total_price_out;
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            .catch(function () {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please check your internet connection.',
                });
                return;
            });
        }
    }
}
</script>