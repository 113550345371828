<template>
    <div>
        <div v-if="dataFetch">
            <p>Sedang memuat data...</p>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Hasil Pengumuman</h5>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!is_empty">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">No Pendaftaran</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{ data_detail.no_pendaftaran }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">NISN</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{ data_detail.identitas.nisn }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Lengkap</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{ data_detail.identitas.nama_lengkap }}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Peminatan / Kelas yang dipilih</div>
                                <div class="font-weight-600" style="text-transform:uppercase;">{{ data_detail.peminatan_rapor.peminatan }}</div>
                            </div>                           
                         </div>
                    </div>

                    <div class="col-md-4 text-center">                        
                        <div class="d-flex align-items-center justify-content-between mb-0">
                            <div style="vertical-align: top !important;"> 
                                <p class="alert alert-success mb-0" role="alert">
                                    <b>Selamat! Kamu Lulus dan Resmi Diterima sebagai Siswa</b>
                                    <br/>
                                    <br/>
                                    Dengan penuh kebahagiaan, kami menginformasikan bahwa Kamu telah resmi diterima sebagai siswa di madrasah ini. Selamat bergabung!<br/><br/>
                                    Terima kasih telah mengikuti proses ini dengan baik. Kami berharap perjalanan belajarmu di sini akan menjadi pengalaman yang berharga dan penuh kesuksesan.
                                </p>
                            </div>
                        </div>

                        <hr/>
                        <button :disabled="is_download" v-on:click="cetakLulus()" type="button" class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                            <span>{{is_download ? 'Loading...':'Cetak Bukti Kelulusan'}}</span>
                            <span class="ml-auto">🖨️</span>
                        </button>
                    </div>
                </div>

                <div class="row align-items-center" v-if="is_empty">
                    <div class="col-md-12">
                        <div class="page-header-title text-center">
                            <img style="width:40%" src="assets/images/small-study.jpg" alt="" class="img-fluid mb-4">
                            <h5 class="m-t-10">
                                Pengumuman Belum Tersedia
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'HasilPengumuman',
        metaInfo: {
            title: 'Hasil Pengumuman - PPDB',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                data_detail : [],
                submitted   : false,
                waiting     : false,
                dataFetch   : true,
                is_empty    : false,
                is_download : false
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'ppdb/hasil-pengumuman';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataFetch   = false;
                        let getResponse  = res.data.data;
                        this.data_detail = getResponse;
                    } else {
                        this.is_empty = true;
                    }
                });
            },
            cetakLulus: function() {
                this.is_download = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/pembiayaan/cetak-lulus', {
                    params : {
                        id : this.data_detail.ppdb_formulir_id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Lulus'}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }

                    this.is_download = false;
                })
            }            
        }
    }
</script>