<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Pembiayaan</h5>
                        </div>
                        <div class="row" v-if="dataFetch">
                            <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                                <div class="card d-block border rounded-lg p-3 m-t-10">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="mr-3 w-100">
                                            <div class="d-flex align-items-center">
                                                <div class="w-100">
                                                    <div class="skeleton-bar text-center">
                                                        <div class="sb-sm mb-2"></div>
                                                        <hr/>
                                                        <div class="sb-lg sb-thin mb-2"></div>
                                                        <div class="sb-lg sb-thin mb-4"></div>
                                                        <div class="sb-lg"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="!dataFetch">
                            <div class="col-lg-6" v-for="(item, index) in dataList" :key="index">
                                <div class="card card-theory-no-hover border">
                                    <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                                    <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/default-cover.png" alt="cover">
                                    <div class="card-body d-flex flex-column">
                                        <h5 class="card-title text-short">
                                            <a href="javascript:void(0)">{{item.title}}</a>
                                        </h5>

                                        <div v-for="(itemDetail, indexDetail) in item.data_tagihan" :key="indexDetail" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                            <div class="mr-3">{{ itemDetail.nama_tagihan }}</div>
                                            <div class="font-weight-600 text-right">
                                                {{ itemDetail.text_jumlah_tagihan }}
                                                <br/>
                                                <span v-if="itemDetail.lunas == 0" class="badge badge-danger">Belum Lunas</span>
                                                <span v-if="itemDetail.lunas == 1" class="badge badge-success">Sudah Lunas</span>
                                            </div>
                                        </div>


                                        <div v-if="(item.daftar_ulang == 0 && item.kirim_pembayaran == 0)" class="alert alert-warning text-center" role="alert">
                                            <b>Ayo Segera lunasi tagihan & lakukan daftar ulang untuk proses finalisasi/pengumuman hasil akhir.</b>
                                        </div>

                                        <div v-if="item.daftar_ulang == 1 && item.lulus == 1 && item.kirim_pembayaran == 2" class="alert alert-info text-center " role="alert">
                                            <b>Selamat! Sekarang kamu telah resmi menjadi siswa disekolah ini.</b>
                                        </div>

                                        <div v-if="item.kirim_pembayaran == 1" class="alert alert-warning text-center mb-0" role="alert">
                                            Bukti pembayaran berhasil dikirim!<br/>Panitia akan segera memeriksa dan melakukan verifikasi. Mohon ditunggu.
                                        </div>

                                        <div v-if="item.kirim_pembayaran == 0" class="alert alert-danger text-center mb-0" role="alert">
                                            Kamu belum mengirimkan bukti pembayaran.
                                        </div>

                                        <button v-on:click="viewModalUpload(item)" type="button" class="btn btn-primary rounded btn-block m-t-10" data-toggle="modal" data-target="#modalUpload"><i class="fa fa-camera"></i> Upload Bukti Pembayaran</button>

                                        <div class="d-grid mb-0 mt-2">
                                            <button :disabled="is_download" v-on:click="cetakLulus(item.ppdb_formulir_id)" v-if="item.lulus == 1" type="button" class="btn btn-outline-primary rounded btn-block mb-0">
                                                <i class="fa fa-download"></i> {{is_download ? 'Loading...':'Cetak Bukti Kelulusan'}}
                                            </button>
                                        </div>
                                        <p class="mt-0" v-if="item.lulus == 0">
                                            Status Daftar Ulang
                                            <br/>
                                            <span v-if="item.daftar_ulang == 1" class="badge badge-success">Sudah Daftar Ulang</span>
                                            <span v-if="item.daftar_ulang == 0" class="badge badge-dark">Belum Melakukan Daftar Ulang</span>
                                        </p>       
                                        <br v-if="item.note_pembiayaan"/>
                                        <div v-if="item.note_pembiayaan">
                                            <span>Catatan:</span>
                                            <br/>
                                            <div v-html="item.note_pembiayaan"></div>
                                        </div>                                      
                                    </div>                        
                                </div>
                            </div>
                        </div>

                        <div>
                            <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                                <hr/>
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </nav>

                            <div v-if="emptyState">
                                <div class="card card-theory-no-hover border p-3">
                                    <empty-state></empty-state>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <!-- Modal -->
            <div class="modal" id="modalUpload" tabindex="-1" aria-hidden="true" data-backdrop="static">
                
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style="overflow: hidden;">
                        <div class="d-block px-3 pt-3 pb-0">
                            <div class="d-flex align-items-center justify-content-between text-sm">
                                <div class="font-weight-600" style="font-size:20px"><i class="fa fa-file-image"></i> Upload Bukti Pembayaran</div>
                                <div>
                                    <button type="button" class="btn btn-link btn-sm p-0 text-danger"
                                        data-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                        <div class="d-block p-3">
                            <div v-for="(itemModal, indexModal) in data_modal_upload.data_tagihan" :key="indexModal" class="d-block mb-1 form-group">
                                <label style="display: flex; justify-content: space-between; width: 100%;">
                                    <span>
                                    {{ itemModal.nama_tagihan }}
                                    <span v-if="itemModal.lunas == 0" class="badge badge-danger">Belum Lunas</span>
                                    <span v-if="itemModal.lunas == 1" class="badge badge-success">Sudah Lunas</span>
                                    </span> 
                                    <b><span>{{ itemModal.text_jumlah_tagihan }}</span></b>
                                </label>

                                <div class="input-group mb-3">
                                    <input type="text" readonly class="form-control" placeholder="Silakan upload (jpg, jpeg, png)" style="cursor:pointer" :value="itemModal.bukti_pembayaran" v-on:click="viewImage(itemModal.bukti_pembayaran)">
                                    <div class="input-group-append">
                                    <button class="btn btn-primary" type="button" @click="triggerFileInput(indexModal, itemModal.id)">
                                        <i class="fa fa-upload"></i> Upload
                                    </button>
                                    </div>
                                </div>

                                <progress v-if="uploadProgress[indexModal] !== undefined" :value="uploadProgress[indexModal]" max="100" style="width:100%"></progress>
                            </div>

                            <button v-on:click="kirimBukti()" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fas fa-paper-plane"></i> Kirim Bukti Pembayaran</button>

                            <div v-if="data_modal_upload.kirim_pembayaran == 1" class="alert alert-warning text-center mt-2" role="alert">
                                Bukti pembayaran berhasil dikirim!<br/>Panitia akan segera memeriksa dan melakukan verifikasi. Mohon ditunggu.
                            </div>

                            <div v-if="data_modal_upload.kirim_pembayaran == 0" class="alert alert-danger text-center mt-2" role="alert">
                                Kamu belum mengirimkan bukti pembayaran.
                            </div>

                            <div v-if="data_modal_upload.kirim_pembayaran == 2" class="alert alert-success text-center mt-2" role="alert">
                                Pembayaran berhasil diverifikasi!<br/>Terima kasih telah melakukan pembayaran.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'FormulirPendaftaran',
        metaInfo: {
            title: 'Pembiayaan',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                is_download     : false,
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                data_modal_upload : [],
                files : {},
                uploadProgress : {}
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/pembiayaan-list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            cetakLulus: function(id) {
                this.is_download = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'ppdb/pembiayaan/cetak-lulus', {
                    params : {
                        id : id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "Hasil gagal didapatkan! silahkan hubungi admin untuk informasi lebih lanjut",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Kartu Bukti Lulus'}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }

                    this.is_download = false;
                })
            },
            viewModalUpload: function(item) {
                this.data_modal_upload = item;
            },  
            triggerFileInput(index, tagihan_id) {
                let fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'image/x-png,image/jpeg';
                fileInput.onchange = (event) => this.uploadFile(event, index, tagihan_id);
                fileInput.click();
            },
            uploadFile(event, index, tagihan_id) {
                let file = event.target.files[0];
                if (!file) {
                    alert('Tidak ada file yang dipilih.');
                    return;
                }

                let formData = new FormData();
                formData.append('ppdb_komponen_biaya_id', tagihan_id);
                formData.append('ppdb_formulir_id', this.data_modal_upload.ppdb_formulir_id);
                formData.append('file', file);
                this.$set(this.uploadProgress, index, 0);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/upload-bukti-pembayaran', formData, {
                    onUploadProgress: function (progressEvent) {
                        this.$set(this.uploadProgress, index, Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    }.bind(this)
                })
                .then(response => {
                    this.$set(this.uploadProgress, index, 100);

                    setTimeout(() => {
                        this.$delete(this.uploadProgress, index);
                    }, 2000);                  

                    if (response.data.status) {
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.getData();

                            setTimeout(() => {
                                this.dataList.forEach(element => {
                                    if(element.id == this.data_modal_upload.id) {
                                        this.data_modal_upload  = element;
                                    }
                                });
                            }, 1000);
                        }
                    });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
                .catch(error => {
                    console.error('Upload gagal:', error);
                    this.$set(this.uploadProgress, index, 0); // Reset progress jika gagal
                });
            },
            viewImage: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            kirimBukti: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kirim bukti ?',
                    text: 'Apakah kamu yakin akan mengirim bukti persyaratan ? ',
                    confirmButtonText: 'Kirim',
                    showCancelButton: true,
                    cancelButtonText: 'Cek kembali',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/kirim-bukti-pembayaran', {
                            ppdb_formulir_id: this.data_modal_upload.ppdb_formulir_id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();

                                        setTimeout(() => {
                                            this.dataList.forEach(element => {
                                                if(element.id == this.data_modal_upload.id) {
                                                    this.data_modal_upload  = element;
                                                }
                                            });
                                        }, 1000);                                        
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });                
            }
        }
    }
</script>
