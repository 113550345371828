<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Formulir Pendaftaran</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="margin-top: -10px;">
                                <div class="input-group m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top:-10px !important" class="row" v-if="dataFetch">
            <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                <div class="card d-block border rounded-lg p-3 m-t-10">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="mr-3 w-100">
                            <div class="d-flex align-items-center">
                                <div class="w-100">
                                    <div class="skeleton-bar text-center">
                                        <div class="sb-sm mb-2"></div>
                                        <hr/>
                                        <div class="sb-lg sb-thin mb-2"></div>
                                        <div class="sb-lg sb-thin mb-4"></div>
                                        <div class="sb-lg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top:-10px !important" class="row" v-if="!dataFetch">
            <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                <div class="card border">
                    <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                    <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/default-cover.png" alt="cover">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title text-short">
                            <a href="javascript:void(0)">{{item.title}}</a>
                        </h5>

                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Tahun Ajaran</div>
                            <div class="font-weight-600">{{ item.tahun_ajaran }}</div>
                        </div>

                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3">
                            <div class="mr-3">Status Formulir</div>
                            <div class="font-weight-600">
                                <span v-if="item.is_opened == 1" class="badge badge-success">Pendaftaran Dibuka</span>
                                <span v-if="item.is_opened == 0" class="badge badge-danger">Pendaftaran Ditutup</span>
                            </div>
                        </div>

                        <div v-if="(item.status_member.id == '' && item.is_opened == 1)">
                            <div class="alert alert-warning text-center" role="alert">
                                Kamu belum terdaftar pada formulir ini. Klik tombol dibawah ini untuk mendapatkan formulir
                            </div>

                            <div  class="d-grid mb-0">
                                <button v-on:click="ambilFormulir(item.id)" type="button" class="btn btn-primary rounded btn-block">
                                    <i class="fa fa-file-alt"></i> Ambil Formulir Pendaftaran
                                </button>
                            </div>
                        </div>

                        <div v-if="item.status_member.id != ''">
                            <div v-if="item.status_member.status_pendaftaran == 'belum_melengkapi'" class="alert alert-danger text-center" role="alert">
                                Kamu belum melengkapi data pada formulir ini.
                            </div>

                            <div v-if="item.status_member.status_pendaftaran == 'menunggu_verifikasi'" class="alert alert-warning text-center" role="alert">
                                Menunggu Hasil Verifikasi Data & Berkas Pendaftaran.
                            </div>

                            <div v-if="item.status_member.status_pendaftaran == 'lolos_verifikasi'" class="alert alert-success text-center" role="alert">
                                <b>Selamat!</b><br/>Data & Berkas kamu telah lolos tahap verifikasi.
                            </div>

                            <div v-if="item.status_member.status_pendaftaran == 'gagal_verifikasi'" class="alert alert-danger text-center" role="alert">
                                <b>Verifikasi Gagal (Ditolak)</b>.
                            </div>

                            <div  class="d-grid mb-0">
                                <button v-on:click="goToDetail(item.id)" type="button" class="btn btn-outline-primary rounded btn-block">
                                    <i class="fa fa-file-alt"></i> Lihat Formulir
                                </button>
                            </div>
                        </div>

                        <hr/>
                        <p class="mb-0">
                            Periode Pendaftaran:
                            <br/>
                            <b>{{ item.text_start_date }}</b> s/d <b>{{ item.text_end_date }}</b>
                        </p>                        
                    </div>
                </div>
            </div>
        </div>

        <div>
            <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                <hr/>
                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
            </nav>

            <div v-if="emptyState">
                <div class="card card-theory-no-hover border p-3">
                    <empty-state></empty-state>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'FormulirPendaftaran',
        metaInfo: {
            title: 'Formulir Pendaftaran',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch       : false,
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            ambilFormulir: function(id) {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin <b>mendaftarkan diri</b> ke formulir pendaftaran ini?',
                    confirmButtonText: 'Iya, Daftarkan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/formulir-register', {
                            id: id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Pendaftaran Formulir Berhasil!',
                                    html: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.goToDetail(id);
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                    }
                });
            },
            goToDetail: function(id) {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : id
                    }
                });
            }
        }
    }
</script>
