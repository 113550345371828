<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a v-on:click="goBack()" href="javascript:void(0)"><i class="fa fa-arrow-left m-r-10"></i></a>
                                Data Orang Tua / Wali
                            </h5>
                        </div>
                    </div>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <h6 class="d-flex justify-content-between align-items-center"  style="cursor:pointer" v-on:click="collapseData('ayah')">
                                    Data Ayah Kandung
                                    <span v-if="is_collapse_ayah" class="fa fa-angle-up"></span>
                                    <span v-if="!is_collapse_ayah" class="fa fa-angle-down"></span>
                                </h6>
                                <hr/>
                                <div class="row" :class="is_collapse_ayah ? 'd-none':''">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.ayah_id">

                                    <div class="form-group col-md-6">
                                        <label for="nama_lengkap">Nama Lengkap <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nama_lengkap"
                                            placeholder="Masukan data..." v-model="form.ayah_nama_lengkap"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_nama_lengkap.$error }">
                                        <div v-if="submitted && !$v.form.ayah_nama_lengkap.required" class="invalid-feedback">Nama Lengkap Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="nisn">NIK <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nisn"
                                            placeholder="Masukan data..." v-model="form.ayah_nik"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_nik.$error }">
                                        <div v-if="submitted && !$v.form.ayah_nik.required" class="invalid-feedback">NIK Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ayah_tempat_lahir">Tempat Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="ayah_tempat_lahir"
                                            placeholder="Masukan data..." v-model="form.ayah_tempat_lahir"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_tempat_lahir.$error }">
                                        <div v-if="submitted && !$v.form.ayah_tempat_lahir.required" class="invalid-feedback">Tempat Lahir Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ayah_tanggal_lahir">Tanggal Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="date" class="form-control" id="ayah_tanggal_lahir"
                                            v-model="form.ayah_tanggal_lahir" :class="{ 'is-invalid': submitted && $v.form.ayah_tanggal_lahir.$error }">
                                        <div v-if="submitted && !$v.form.ayah_tanggal_lahir.required" class="invalid-feedback">Tanggal Lahir Ayah wajib diisi!</div>
                                    </div>                                    

                                    <div class="form-group col-md-6">
                                        <label for="status">Status <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ayah_status"
                                            v-model="form.ayah_status" :class="{ 'is-invalid': submitted && $v.form.ayah_status.$error }">
                                            <option value="">Pilih Status</option>
                                            <option value="hidup">Hidup</option>
                                            <option value="sudah meninggal">Sudah Meninggal</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.ayah_status.required" class="invalid-feedback">Status Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ayah_pendidikan_terakhir">Pendidikan Terakhir</label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ayah_pendidikan_terakhir"
                                            v-model="form.ayah_pendidikan_terakhir" :class="{ 'is-invalid': submitted && $v.form.ayah_pendidikan_terakhir.$error }">
                                            <option value="">Pilih Pendidikan Terakhir</option>
                                            <option value="SD">SD</option>
                                            <option value="SMP">SMP/SLTP Sederajat</option>
                                            <option value="SMA">SMA/SLTA Sederajat</option>
                                            <option value="D1">Diploma 1</option>
                                            <option value="D2">Diploma 2</option>
                                            <option value="D3">Diploma 3</option>
                                            <option value="D4">Diploma 4 / S1</option>
                                            <option value="S1">Sarjana (S1)</option>
                                            <option value="S2">Magister (S2)</option>
                                            <option value="S3">Doktor (S3)</option>                                            
                                        </select>
                                        <div v-if="submitted && !$v.form.ayah_pendidikan_terakhir.required" class="invalid-feedback">Pendidikan Terakhir Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="pekerjaan_utama">Pekerjaan Utama <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="pekerjaan_utama"
                                            placeholder="Masukan data..." v-model="form.ayah_pekerjaan_utama"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_pekerjaan_utama.$error }">
                                        <div v-if="submitted && !$v.form.ayah_pekerjaan_utama.required" class="invalid-feedback">Pekerjaan Utama Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ayah_penghasilan_perbulan">Penghasilan Perbulan <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ayah_penghasilan_perbulan"
                                            v-model="form.ayah_penghasilan_perbulan" :class="{ 'is-invalid': submitted && $v.form.ayah_penghasilan_perbulan.$error }">
                                            <option value="">Pilih Penghasilan Per Bulan</option>
                                            <option value="<1jt">&lt; 1 juta</option>
                                            <option value="1-3jt">1 juta - 3 juta</option>
                                            <option value="3-5jt">3 juta - 5 juta</option>
                                            <option value="5-10jt">5 juta - 10 juta</option>
                                            <option value="10-20jt">10 juta - 20 juta</option>
                                            <option value=">20jt">&gt; 20 juta</option>                                          
                                        </select>
                                        <div v-if="submitted && !$v.form.ayah_penghasilan_perbulan.required" class="invalid-feedback">Penghasilan Perbulan Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="no_handphone">No Handphone <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="no_handphone"
                                            placeholder="Masukan data..." v-model="form.ayah_no_handphone"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_no_handphone.$error }">
                                        <div v-if="submitted && !$v.form.ayah_no_handphone.required" class="invalid-feedback">No Handphone Ayah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ayah_status_tempat_tinggal">Status Tempat Tinggal<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="ayah_status_tempat_tinggal" v-model="form.ayah_status_tempat_tinggal"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_status_tempat_tinggal.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="milik sendiri">Milik Sendiri</option>
                                            <option value="sewa/kontrak">Sewa/Kontrak</option>
                                            <option value="milik kerabat/sodara">Milik Kerabat/Sodara</option>
                                            <option value="milik orang tua">Milih Orang Tua</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.ayah_status_tempat_tinggal.required" class="invalid-feedback">Status Tempat Tinggal wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="alamat">Alamat <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="alamat"
                                            placeholder="Masukan data..." v-model="form.ayah_alamat"
                                            :class="{ 'is-invalid': submitted && $v.form.ayah_alamat.$error }">
                                        <div v-if="submitted && !$v.form.ayah_alamat.required" class="invalid-feedback">Alamat Ayah wajib diisi!</div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block mb-1 mt-2">
                                <h6 class="d-flex justify-content-between align-items-center"  style="cursor:pointer" v-on:click="collapseData('ibu')">
                                    Data Ibu Kandung
                                    <span v-if="is_collapse_ibu" class="fa fa-angle-up"></span>
                                    <span v-if="!is_collapse_ibu" class="fa fa-angle-down"></span>
                                </h6>
                                <hr/>
                                <div class="row" :class="is_collapse_ibu ? 'd-none':''">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.ibu_id">

                                    <div class="form-group col-md-6">
                                        <label for="nama_lengkap">Nama Lengkap <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nama_lengkap"
                                            placeholder="Masukan data..." v-model="form.ibu_nama_lengkap"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_nama_lengkap.$error }">
                                        <div v-if="submitted && !$v.form.ibu_nama_lengkap.required" class="invalid-feedback">Nama Lengkap Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="nisn">NIK <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nisn"
                                            placeholder="Masukan data..." v-model="form.ibu_nik"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_nik.$error }">
                                        <div v-if="submitted && !$v.form.ibu_nik.required" class="invalid-feedback">NIK Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_tempat_lahir">Tempat Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="ibu_tempat_lahir"
                                            placeholder="Masukan data..." v-model="form.ibu_tempat_lahir"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_tempat_lahir.$error }">
                                        <div v-if="submitted && !$v.form.ibu_tempat_lahir.required" class="invalid-feedback">Tempat Lahir Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_tanggal_lahir">Tanggal Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="date" class="form-control" id="ibu_tanggal_lahir"
                                            v-model="form.ibu_tanggal_lahir" :class="{ 'is-invalid': submitted && $v.form.ibu_tanggal_lahir.$error }">
                                        <div v-if="submitted && !$v.form.ibu_tanggal_lahir.required" class="invalid-feedback">Tanggal Lahir Ibu wajib diisi!</div>
                                    </div>                                    

                                    <div class="form-group col-md-6">
                                        <label for="status">Status <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ibu_status"
                                            v-model="form.ibu_status" :class="{ 'is-invalid': submitted && $v.form.ibu_status.$error }">
                                            <option value="">Pilih Status</option>
                                            <option value="hidup">Hidup</option>
                                            <option value="sudah meninggal">Sudah Meninggal</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.ibu_status.required" class="invalid-feedback">Status Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_pendidikan_terakhir">Pendidikan Terakhir</label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ibu_pendidikan_terakhir"
                                            v-model="form.ibu_pendidikan_terakhir" :class="{ 'is-invalid': submitted && $v.form.ibu_pendidikan_terakhir.$error }">
                                            <option value="">Pilih Pendidikan Terakhir</option>
                                            <option value="SD">SD</option>
                                            <option value="SMP">SMP/SLTP Sederajat</option>
                                            <option value="SMA">SMA/SLTA Sederajat</option>
                                            <option value="D1">Diploma 1</option>
                                            <option value="D2">Diploma 2</option>
                                            <option value="D3">Diploma 3</option>
                                            <option value="D4">Diploma 4 / S1</option>
                                            <option value="S1">Sarjana (S1)</option>
                                            <option value="S2">Magister (S2)</option>
                                            <option value="S3">Doktor (S3)</option>                                            
                                        </select>
                                        <div v-if="submitted && !$v.form.ibu_pendidikan_terakhir.required" class="invalid-feedback">Pendidikan Terakhir Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="pekerjaan_utama">Pekerjaan Utama <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="pekerjaan_utama"
                                            placeholder="Masukan data..." v-model="form.ibu_pekerjaan_utama"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_pekerjaan_utama.$error }">
                                        <div v-if="submitted && !$v.form.ibu_pekerjaan_utama.required" class="invalid-feedback">Pekerjaan Utama Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_penghasilan_perbulan">Penghasilan Perbulan <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="ibu_penghasilan_perbulan"
                                            v-model="form.ibu_penghasilan_perbulan" :class="{ 'is-invalid': submitted && $v.form.ibu_penghasilan_perbulan.$error }">
                                            <option value="">Pilih Penghasilan Per Bulan</option>
                                            <option value="<1jt">&lt; 1 juta</option>
                                            <option value="1-3jt">1 juta - 3 juta</option>
                                            <option value="3-5jt">3 juta - 5 juta</option>
                                            <option value="5-10jt">5 juta - 10 juta</option>
                                            <option value="10-20jt">10 juta - 20 juta</option>
                                            <option value=">20jt">&gt; 20 juta</option>                                          
                                        </select>
                                        <div v-if="submitted && !$v.form.ibu_penghasilan_perbulan.required" class="invalid-feedback">Penghasilan Perbulan Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="no_handphone">No Handphone <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="no_handphone"
                                            placeholder="Masukan data..." v-model="form.ibu_no_handphone"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_no_handphone.$error }">
                                        <div v-if="submitted && !$v.form.ibu_no_handphone.required" class="invalid-feedback">No Handphone Ibu wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_status_tempat_tinggal">Status Tempat Tinggal<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="ibu_status_tempat_tinggal" v-model="form.ibu_status_tempat_tinggal"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_status_tempat_tinggal.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="milik sendiri">Milik Sendiri</option>
                                            <option value="sewa/kontrak">Sewa/Kontrak</option>
                                            <option value="milik kerabat/sodara">Milik Kerabat/Sodara</option>
                                            <option value="milik orang tua">Milih Orang Tua</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.ibu_status_tempat_tinggal.required" class="invalid-feedback">Status Tempat Tinggal wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="alamat">Alamat <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="alamat"
                                            placeholder="Masukan data..." v-model="form.ibu_alamat"
                                            :class="{ 'is-invalid': submitted && $v.form.ibu_alamat.$error }">
                                        <div v-if="submitted && !$v.form.ibu_alamat.required" class="invalid-feedback">Alamat Ibu wajib diisi!</div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block mb-1 mt-2">
                                <h6 class="d-flex justify-content-between align-items-center"  style="cursor:pointer" v-on:click="collapseData('wali')">
                                    Data Wali
                                    <span v-if="is_collapse_wali" class="fa fa-angle-up"></span>
                                    <span v-if="!is_collapse_wali" class="fa fa-angle-down"></span>
                                </h6>
                                <hr/>
                                <div class="row" :class="is_collapse_wali ? 'd-none':''">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.wali_id">

                                    <div class="form-group col-md-6">
                                        <label for="nama_lengkap">Nama Lengkap</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nama_lengkap"
                                            placeholder="Masukan data..." v-model="form.wali_nama_lengkap">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="nisn">NIK</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nisn"
                                            placeholder="Masukan data..." v-model="form.wali_nik">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="wali_tempat_lahir">Tempat Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="wali_tempat_lahir"
                                            placeholder="Masukan data..." v-model="form.wali_tempat_lahir">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="wali_tanggal_lahir">Tanggal Lahir</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="date" class="form-control" id="wali_tanggal_lahir"
                                            v-model="form.wali_tanggal_lahir">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="status">Status</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="status"
                                            placeholder="Masukan data..." v-model="form.wali_status">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="wali_pendidikan_terakhir">Pendidikan Terakhir</label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="wali_pendidikan_terakhir"
                                            v-model="form.wali_pendidikan_terakhir">
                                            <option value="">Pilih Pendidikan Terakhir</option>
                                            <option value="SD">SD</option>
                                            <option value="SMP">SMP/SLTP Sederajat</option>
                                            <option value="SMA">SMA/SLTA Sederajat</option>
                                            <option value="D1">Diploma 1</option>
                                            <option value="D2">Diploma 2</option>
                                            <option value="D3">Diploma 3</option>
                                            <option value="D4">Diploma 4 / S1</option>
                                            <option value="S1">Sarjana (S1)</option>
                                            <option value="S2">Magister (S2)</option>
                                            <option value="S3">Doktor (S3)</option>                                            
                                        </select>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="pekerjaan_utama">Pekerjaan Utama</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="pekerjaan_utama"
                                            placeholder="Masukan data..." v-model="form.wali_pekerjaan_utama">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="wali_penghasilan_perbulan">Penghasilan Perbulan</label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="wali_penghasilan_perbulan"
                                            v-model="form.wali_penghasilan_perbulan">
                                            <option value="">Pilih Penghasilan Per Bulan</option>
                                            <option value="<1jt">&lt; 1 juta</option>
                                            <option value="1-3jt">1 juta - 3 juta</option>
                                            <option value="3-5jt">3 juta - 5 juta</option>
                                            <option value="5-10jt">5 juta - 10 juta</option>
                                            <option value="10-20jt">10 juta - 20 juta</option>
                                            <option value=">20jt">&gt; 20 juta</option>                                          
                                        </select>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="no_handphone">No Handphone</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="no_handphone"
                                            placeholder="Masukan data..." v-model="form.wali_no_handphone">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="ibu_status_tempat_tinggal">Status Tempat status_tempat_tinggal</label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="wali_status_tempat_tinggal" v-model="form.wali_status_tempat_tinggal">
                                            <option value="">Pilih...</option>
                                            <option value="milik sendiri">Milik Sendiri</option>
                                            <option value="sewa/kontrak">Sewa/Kontrak</option>
                                            <option value="milik kerabat/sodara">Milik Kerabat/Sodara</option>
                                            <option value="milik orang tua">Milih Orang Tua</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="alamat">Alamat</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="alamat"
                                            placeholder="Masukan data..." v-model="form.wali_alamat">
                                    </div>
                                </div>
                            </div>

                            <small>Keterangan: Tanda (<span class="text-danger">*</span>) Wajib Diisi</small>
                        </div>

                        <div class="col-md-4 text-center">
                            <div v-if="dataDetail.member.terkirim == 1" class="alert alert-info text-center" role="alert">
                                <b>Keterangan:</b><br/> Kamu tidak bisa melakukan perubahan ketika data sudah terkirim/sedang dalam proses verifikasi.
                            </div>

                            <button
                                :disabled="dataDetail.member.terkirim == 1 ? true:false"
                                type="submit"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span><i class="fa fa-save"></i> Simpan Data</span>
                                <span class="ml-auto">💾</span>
                            </button>
                            <button
                                v-on:click="alertBack()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Kembali</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Ortu',
        metaInfo: {
            title: 'Data Orang Tua / Wali',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                form        : {},
                submitted   : false,
                waiting     : false,
                is_collapse_ayah : false,
                is_collapse_ibu  : false,
                is_collapse_wali : false,
            }
        },
        created() {
            this.getData()
        },
        validations: {
            form: {
                ayah_nik: {
                    required
                },
                ayah_nama_lengkap: {
                    required
                },
                ayah_tempat_lahir: {
                    required
                },
                ayah_tanggal_lahir: {
                    required
                },
                ayah_status: {
                    required
                },
                ayah_pendidikan_terakhir: {
                    required
                },
                ayah_pekerjaan_utama: {
                    required
                },
                ayah_no_handphone: {
                    required
                },
                ayah_penghasilan_perbulan: {
                    required
                },
                ayah_status_tempat_tinggal: {
                    required
                },
                ayah_alamat: {
                    required
                },
                ibu_nik: {
                    required
                },
                ibu_nama_lengkap: {
                    required
                },
                ibu_tempat_lahir: {
                    required
                },
                ibu_tanggal_lahir: {
                    required
                },
                ibu_status: {
                    required
                },
                ibu_pendidikan_terakhir: {
                    required
                },
                ibu_pekerjaan_utama: {
                    required
                },
                ibu_no_handphone: {
                    required
                },
                ibu_penghasilan_perbulan: {
                    required
                },
                ibu_status_tempat_tinggal: {
                    required
                },
                ibu_alamat: {
                    required
                }                        
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.ppdb_formulir_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data
                        this.getIdentitas();
                    } else {
                        this.dataFetch  = false;
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            getIdentitas: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/orangtua-wali';
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.form = res.data.data
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : this.$route.params.ppdb_formulir_id
                    }
                });
            },
            alertBack: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin kembali ke halaman sebelumnya?',
                    confirmButtonText: 'Iya, Kembali',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.goBack()
                    }
                });                
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // Validasi form
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                }

                let formData = new FormData();

                formData.append('ayah_id', this.form.ayah_id);
                formData.append('ayah_nama_lengkap', this.form.ayah_nama_lengkap);
                formData.append('ayah_nik', this.form.ayah_nik);
                formData.append('ayah_tempat_lahir', this.form.ayah_tempat_lahir);
                formData.append('ayah_tanggal_lahir', this.form.ayah_tanggal_lahir);
                formData.append('ayah_status', this.form.ayah_status);
                formData.append('ayah_pendidikan_terakhir', this.form.ayah_pendidikan_terakhir);
                formData.append('ayah_pekerjaan_utama', this.form.ayah_pekerjaan_utama);
                formData.append('ayah_no_handphone', this.form.ayah_no_handphone);
                formData.append('ayah_penghasilan_perbulan', this.form.ayah_penghasilan_perbulan);
                formData.append('ayah_status_tempat_tinggal', this.form.ayah_status_tempat_tinggal);
                formData.append('ayah_alamat', this.form.ayah_alamat);

                formData.append('ibu_id', this.form.ibu_id);
                formData.append('ibu_nama_lengkap', this.form.ibu_nama_lengkap);
                formData.append('ibu_nik', this.form.ibu_nik);
                formData.append('ibu_tempat_lahir', this.form.ibu_tempat_lahir);
                formData.append('ibu_tanggal_lahir', this.form.ibu_tanggal_lahir);
                formData.append('ibu_status', this.form.ibu_status);
                formData.append('ibu_pendidikan_terakhir', this.form.ibu_pendidikan_terakhir);
                formData.append('ibu_pekerjaan_utama', this.form.ibu_pekerjaan_utama);
                formData.append('ibu_no_handphone', this.form.ibu_no_handphone);
                formData.append('ibu_penghasilan_perbulan', this.form.ibu_penghasilan_perbulan);
                formData.append('ibu_status_tempat_tinggal', this.form.ibu_status_tempat_tinggal);
                formData.append('ibu_alamat', this.form.ibu_alamat);

                formData.append('wali_id', this.form.wali_id);
                formData.append('wali_nama_lengkap', this.form.wali_nama_lengkap);
                formData.append('wali_nik', this.form.wali_nik);
                formData.append('wali_tempat_lahir', this.form.wali_tempat_lahir);
                formData.append('wali_tanggal_lahir', this.form.wali_tanggal_lahir);
                formData.append('wali_status', this.form.wali_status);
                formData.append('wali_pendidikan_terakhir', this.form.wali_pendidikan_terakhir);
                formData.append('wali_pekerjaan_utama', this.form.wali_pekerjaan_utama);
                formData.append('wali_no_handphone', this.form.wali_no_handphone);
                formData.append('wali_penghasilan_perbulan', this.form.wali_penghasilan_perbulan);
                formData.append('wali_status_tempat_tinggal', this.form.wali_status_tempat_tinggal);
                formData.append('wali_alamat', this.form.wali_alamat);

                formData.append('ppdb_formulir_id',  this.$route.params.ppdb_formulir_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/orangtua-wali/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Silakan cek koneksi internet Anda.',
                    });
                    this.waiting = false;
                });
            },
            collapseData: function(params) {
                if(params == 'ayah') {
                    this.is_collapse_ayah = !this.is_collapse_ayah;
                }

                if(params == 'ibu') {
                    this.is_collapse_ibu = !this.is_collapse_ibu;
                }

                if(params == 'wali') {
                    this.is_collapse_wali = !this.is_collapse_wali;
                }
            }
        }
    }
</script>