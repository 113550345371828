<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Hasil Pengumuman Kelulusan Peserta</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a class="btn btn-primary btn-sm rounded" href="javascript:void(0)" v-on:click="getData()">Muat Ulang</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch">
                                    <div v-if="dataList.length > 0" >
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:5%" class="text-center">No</th>
                                                        <th>Kelas / Peminatan</th>
                                                        <th style="width:10%" class="text-center">Total Peserta Lulus</th>
                                                        <th class="text-center" style="width:15%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="dataList.length > 0">
                                                    <tr v-for="(item, index) in dataList" :key="index">
                                                        <td class="text-center">
                                                            {{ index+1 }}
                                                        </td>
                                                        <td>{{ item.peminatan }}</td>
                                                        <td class="text-center">{{ item.total_users }} Peserta</td>
                                                        <td>
                                                            <a data-toggle="modal" data-target="#modal_hasil_pengumuman" href="javascript:void(0)" v-on:click="showUser(item)" class="btn btn-outline-primary btn-sm rounded m-r-10"><i class="fas fa-users"></i> Lihat Detail Peserta</a>
                                                            <br v-if="(item.peminatan == 'Kelas Reguler' || item.peminatan == 'Kelas Riset' || item.peminatan == 'Kelas Sains' || item.peminatan == 'Kelas Multimedia')"/>
                                                            <a style="margin-top:10px" v-if="(item.peminatan == 'Kelas Reguler' || item.peminatan == 'Kelas Riset' || item.peminatan == 'Kelas Sains' || item.peminatan == 'Kelas Multimedia')" data-toggle="modal" data-target="#modal_upload_nilai" href="javascript:void(0)" v-on:click="showUser(item)" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-upload"></i> Upload Nilai Asesmen</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="dataList.length < 1">
                                                    <tr colspan="9">
                                                        <td>Data tidak ditemukan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modal_hasil_pengumuman" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-users"></i> Daftar Peserta</div>
                            <div>
                                <button id="btnCloseModalStatus" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Peminatan</div>
                            <div class="font-weight-600">{{ data_modal.peminatan }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Total Peserta Lulus</div>
                            <div class="font-weight-600">{{ data_modal.total_users }} Orang</div>
                        </div>
                        <div v-if="data_modal.total_users > 0" class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="font-weight-600"><button class="btn btn-primary btn-sm rounded" v-on:click="downloadPesertaLulus()">Download Data Peserta Lulus</button></div>
                            <div class="font-weight-600"><button :disabled="is_download_emis" class="btn btn-outline-primary btn-sm rounded" v-on:click="downloadPesertaLulusExcel()">{{is_download_emis ? 'Loading...':'Download Data Peserta EMIS'}}</button></div>
                        </div>
                        <input type="text" class="form-control" placeholder="Cari data berdasarkan nama peserta atau no pendaftaran..."/>
                        <div class="table-responsive mt-3">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td style="width: 5%;" class="text-center">No</td>
                                        <td>Nama Peserta</td>
                                        <td style="width: 5%;" class="text-center">No Pendaftaran</td>
                                        <td style="width: 5%;" class="text-center">Aksi</td>
                                    </tr>
                                </thead>
                                <tbody v-if="data_modal.detail_users.length > 0">
                                    <tr v-for="(itemModal, indexModal) in data_modal.detail_users" :key="indexModal">
                                        <td class="text-center">{{ ++indexModal }}</td>
                                        <td>{{ itemModal.fullname }}</td>
                                        <td>{{ itemModal.no_pendaftaran }}</td>
                                        <td>
                                            <button :disabled="is_download" v-on:click="downloadHasilFormulir(itemModal.users_id, itemModal.fullname, itemModal.no_pendaftaran, itemModal.ppdb_formulir_id)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-primary btn-block">
                                                <span>{{ is_download ? 'Loading...':'Download Hasil Formulir (PDF)' }}</span>
                                                <span class="ml-auto">🖨️</span>
                                            </button>

                                            <button :disabled="is_download" v-on:click="downloadHasilFormulirExcel(itemModal.users_id, itemModal.fullname, itemModal.no_pendaftaran, itemModal.ppdb_formulir_id)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-outline-primary btn-block">
                                                <span>{{ is_download ? 'Loading...':'Download Format Excel' }}</span>
                                                <span class="ml-auto">🖨️</span>
                                            </button>

                                            <button v-on:click="deleteData(itemModal.users_id)" type="button" class="btn d-flex justify-content-between align-items-center rounded btn-sm btn-danger btn-block">
                                                <span>Hapus Peserta</span>
                                                <span class="ml-auto">🗑️</span>
                                            </button>                                            
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data_modal.detail_users.length < 1">
                                    <tr>
                                        <td colspan="4">Data tidak tersedia</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>          
            </div>          
        </div>
        <div class="modal" id="modal_upload_nilai" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-upload"></i> Upload Nilai Asesmen Peserta</div>
                            <div>
                                <button id="btnCloseModalUpload" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="data_modal.total_users > 0" class="d-block px-3 pt-3 pb-0">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mt-1">
                                        <label>Upload File Nilai</label>
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        <div><small>Template Import Nilai: <a v-on:click="downloadFormatImport()" href="javascript:void()">Downnload Disini</a></small></div>
                                    </div>
                                    <hr/>
                                    <div class="mt-3">
                                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block btn-sm rounded"><i class="fas fa-upload"></i> {{ waiting ? 'Harap tunggu ...' : 'Upload File' }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr/>
                        <input type="text" class="form-control" placeholder="Cari data berdasarkan nama peserta atau no pendaftaran..." v-model="search"/>
                        <div class="table-responsive mt-3">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td style="width: 5%;" class="text-center">No</td>
                                        <td>Nama Peserta</td>
                                        <td style="width: 5%;" class="text-center">No Pendaftaran</td>
                                        <td style="width: 5%;" class="text-center">Nilai Asesmen</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(itemModal, indexModal) in (data_modal.detail_users || []).filter(user => {
                                        return search.trim() === '' || 
                                            user.fullname.toLowerCase().includes(search.toLowerCase()) || 
                                            user.no_pendaftaran.toLowerCase().includes(search.toLowerCase());
                                    })" :key="indexModal">
                                        <td class="text-center">{{ indexModal + 1 }}</td>
                                        <td>{{ itemModal.fullname }}</td>
                                        <td>{{ itemModal.no_pendaftaran }}</td>
                                        <td class="text-center">{{ itemModal.nilai_asesmen || '-' }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="(data_modal.detail_users || []).filter(user => {
                                    return search.trim() === '' || 
                                        user.fullname.toLowerCase().includes(search.toLowerCase()) || 
                                        user.no_pendaftaran.toLowerCase().includes(search.toLowerCase());
                                }).length < 1">
                                    <tr>
                                        <td colspan="4" class="text-center">Data tidak tersedia</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="data_modal.total_users < 1" class="d-block px-3 pt-3 pb-0">
                        <div class="text-center">
                            <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                            <h4 class="m-t-10 mb-3">Data Peserta Tidak Ditemukan</h4>
                        </div>
                    </div>

                </div>          
            </div>          
        </div>
</div>
</template>

<script>
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    name: 'HasilPengumuman',
    metaInfo: {
        title: 'Data Hasil Pengumuman Kelulusan',
        titleTemplate: '%s - Griya Belajar'
    },
    data() {
        return {
            is_download_emis : false,
            is_download : false,
            emptyState  : false,
            dataFetch   : false,
            dataList    : [],
            search      : "", // Tambahin `search` buat filter
            data_modal  : {
                detail_users : []
            },
            form : {
                file : ""
            },
            submitted       : false,
            waiting         : false
        }
    },
    computed: {
        filteredUsers() {
            if (!this.data_modal.detail_users) return [];

            return this.data_modal.detail_users.filter(user => {
                return this.search.trim() === '' || 
                    user.fullname.toLowerCase().includes(this.search.toLowerCase()) || 
                    user.no_pendaftaran.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            let uri = process.env.VUE_APP_APIHOST + 'admin/ppdb/hasil-pengumuman';

            this.dataFetch = true;                
            this.$http.get(uri).then(res => {
                this.emptyState = false;
                this.dataFetch  = false;                
                this.dataList   = res.data.data;
                this.dataList.sort((a, b) => a.peminatan.localeCompare(b.peminatan));
                if (this.dataList.length < 1) {
                    this.emptyState = true;
                }
            });
        },
        showUser(item) {
            this.data_modal = item;
        },
        downloadHasilFormulir(users_id = "", fullname = "", no_pendaftaran = "", id = "") {
            this.is_download = true;
            let title = fullname + " - " + no_pendaftaran;
            this.$http.get(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/cetak-hasil', {
                params : { id, users_id },
                responseType: 'blob'
            })
            .then(response => {
                this.is_download = false;
                if (response.data.type === 'application/json') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Hasil gagal didapatkan! Silahkan hubungi admin untuk informasi lebih lanjut",
                    });       
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Hasil Formulir Pendaftaran - ${title}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
        },
        downloadHasilFormulirExcel(users_id = "", fullname = "", no_pendaftaran = "", id = "") {
            this.is_download = true;
            let title = fullname + " - " + no_pendaftaran;
            this.$http.get(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/cetak-hasil-excel', {
                params: { id, users_id },
                responseType: 'blob'
            })
            .then(response => {
                this.is_download = false;
                if (response.data.type === 'application/json') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Hasil gagal didapatkan! Silahkan hubungi admin untuk informasi lebih lanjut",
                    });
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Hasil Formulir Pendaftaran - ${title}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(() => {
                this.is_download = false;
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: "Terjadi kesalahan saat mengunduh file. Silakan coba lagi nanti.",
                });
            });
        },
        downloadPesertaLulusExcel() {
            this.is_download_emis = true;
            let title = this.data_modal.peminatan
            this.$http.get(process.env.VUE_APP_APIHOST + 'admin/ppdb/formulir/cetak-hasil-excel-emis', {
                params: { peminatan : this.data_modal.peminatan },
                responseType: 'blob'
            })
            .then(response => {
                this.is_download_emis = false;
                if (response.data.type === 'application/json') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Hasil gagal didapatkan! Silahkan hubungi admin untuk informasi lebih lanjut",
                    });
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Hasil Formulir Pendaftaran - ${title}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch(() => {
                this.is_download = false;
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: "Terjadi kesalahan saat mengunduh file. Silakan coba lagi nanti.",
                });
            });
        },
        downloadPesertaLulus() {
            if (!this.data_modal.detail_users || this.data_modal.detail_users.length === 0) {
                console.warn("Data tidak tersedia!");
                return;
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Peserta Lulus");

            // Definisi header
            const headers = ["No", "Nama Lengkap", "No Pendaftaran", "Peminatan"];
            worksheet.addRow(headers);

            // Styling header
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.alignment = { horizontal: "center", vertical: "middle" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });

            // Isi data
            this.data_modal.detail_users.forEach((item, index) => {
                const row = [
                    index + 1,
                    item.fullname,
                    item.no_pendaftaran,
                    item.peminatan,
                ];
                worksheet.addRow(row);
            });

            // Styling border di semua sel
            worksheet.eachRow((row) => {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                });
            });

            // Set auto width kolom
            worksheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const cellValue = cell.value ? cell.value.toString().length : 10;
                    if (cellValue > maxLength) {
                        maxLength = cellValue;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
                console.log(i)
            });

            // Simpan file
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), "Peserta_Lulus.xlsx");
            });
        },
        downloadFormatImport() {
            if (!this.data_modal.detail_users || this.data_modal.detail_users.length === 0) {
                console.warn("Data tidak tersedia!");
                return;
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Peserta Lulus");

            // Definisi header
            const headers = ["No", "Nama Lengkap", "No Pendaftaran", "Nilai Asesmen"];
            worksheet.addRow(headers);

            // Styling header
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
                cell.alignment = { horizontal: "center", vertical: "middle" };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });

            // Isi data
            this.data_modal.detail_users.forEach((item, index) => {
                const row = [
                    index + 1,
                    item.fullname,
                    item.no_pendaftaran,
                    '',
                ];
                worksheet.addRow(row);
            });

            // Styling border di semua sel
            worksheet.eachRow((row) => {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                });
            });

            // Set auto width kolom
            worksheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const cellValue = cell.value ? cell.value.toString().length : 10;
                    if (cellValue > maxLength) {
                        maxLength = cellValue;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength + 2;
                console.log(i)
            });

            // Simpan file
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), "Format_import_nilai_assesmen.xlsx");
            });
        },
        onSubmit: function () {
            if(this.form.file == '') {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: "Harap masukan file import",
                });
            } else {
                this.waiting    = true;
                this.submitted  = true;

                let formData = new FormData();
                formData.append('file', this.form.file);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/ppdb/import-nilai-asesmen', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                if (response.data.status) {
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            this.getData();
                            document.getElementById("btnCloseModalUpload").click();
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                }
                this.waiting = false;
            })
            .catch(() => {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please check your internet connection.',
                });
                this.waiting = false;
            });
            }
        },
        previewFiles(e) {
            const file = e.target.files[0];                
            var fsize = ((file.size/1024)/1024).toFixed(4); // MB
            
            if(fsize >= 5) {
                alert("File yang di upload tidak boleh lebih 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.form.file = file;
            }
        },
        deleteData: function (id) {
            this.$swal({
            icon: 'info',
            title: 'Hapus Data',
            text: 'Apakah kamu yakin ingin menghapus data ini?',
            confirmButtonText: 'Hapus',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            }).then((result) => {
            if (result.value) {
                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/members/delete_batch_permanent', {
                    id: [id]
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.getData();
                                document.getElementById("btnCloseModalStatus").click();
                            }
                        });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
            }
            });
        }        
    }
}
</script>
