<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a v-on:click="goBack()" href="javascript:void(0)"><i class="fa fa-arrow-left m-r-10"></i></a>
                                Upload Berkas (Ijazah/SKHUN/Pas Foto/Dll)
                            </h5>
                        </div>
                    </div>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="row">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.id">

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Pas foto <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="pas_foto"
                                            v-model="form.pas_foto"
                                            placeholder="Silahkan upload pas foto" style="cursor:pointer"
                                            :class="{ 'is-invalid': submitted && $v.form.pas_foto.$error }"
                                            readonly
                                            v-on:click="viewData(form.pas_foto)"
                                            >
                                            <small>Format: .jpeg, .jpg, .png</small>
                                        <div v-if="submitted && !$v.form.pas_foto.required" class="invalid-feedback">File ini wajib diisi!</div>

                                        <input class="d-none" type="file" name="file_pas_foto" id="file_pas_foto" @change="previewFiles_pas_foto" accept="image/x-png,image/gif,image/jpeg">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_pas_foto()" :disabled="upload_pas_foto" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_pas_foto ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Kartu Keluarga <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="kartu_keluarga"
                                            v-model="form.kartu_keluarga"
                                            placeholder="Silahkan upload Kartu Keluarga" style="cursor:pointer"
                                            :class="{ 'is-invalid': submitted && $v.form.kartu_keluarga.$error }"
                                            readonly
                                            v-on:click="viewData(form.kartu_keluarga)"
                                            >
                                            <small>Format: .pdf</small>
                                        <div v-if="submitted && !$v.form.kartu_keluarga.required" class="invalid-feedback">File ini wajib diisi!</div>

                                        <input class="d-none" type="file" name="file_kartu_keluarga" id="file_kartu_keluarga" @change="previewFiles_kartu_keluarga" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_kartu_keluarga()" :disabled="upload_kartu_keluarga" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_kartu_keluarga ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Akta Kelahiran <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="akta_kelahiran"
                                            v-model="form.akta_kelahiran"
                                            placeholder="Silahkan upload Akta Kelahiran" style="cursor:pointer"
                                            :class="{ 'is-invalid': submitted && $v.form.akta_kelahiran.$error }"
                                            readonly
                                            v-on:click="viewData(form.akta_kelahiran)"
                                            >
                                            <small>Format: .pdf</small>
                                        <div v-if="submitted && !$v.form.akta_kelahiran.required" class="invalid-feedback">File ini wajib diisi!</div>

                                        <input class="d-none" type="file" name="file_akta_kelahiran" id="file_akta_kelahiran" @change="previewFiles_akta_kelahiran" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_akta_kelahiran()" :disabled="upload_akta_kelahiran" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_akta_kelahiran ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>
                                                                        
                                    <div class="form-group col-md-6">
                                        <label for="sltp">SKHUN (Jika sudah ada)</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="skhun"
                                            v-model="form.skhun"
                                            placeholder="Silahkan upload SKHUN" style="cursor:pointer"                                            
                                            readonly
                                            v-on:click="viewData(form.skhun)"
                                            >
                                            <small>Format: .pdf</small>

                                        <input class="d-none" type="file" name="file_skhun" id="file_skhun" @change="previewFiles_skhun" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_skhun()" :disabled="upload_skhun" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_skhun ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Ijazah (Jika Sudah Ada)</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="ijazah"
                                            v-model="form.ijazah"
                                            placeholder="Silahkan upload ijazah" style="cursor:pointer"                                            
                                            readonly
                                            v-on:click="viewData(form.ijazah)"
                                            >
                                            <small>Format: .pdf</small>

                                        <input class="d-none" type="file" name="file_ijazah" id="file_ijazah" @change="previewFiles_ijazah" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_ijazah()" :disabled="upload_ijazah" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_ijazah ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">Surat Kelulusan (Jika Sudah Ada)</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="surat_kelulusan"
                                            v-model="form.surat_kelulusan"
                                            placeholder="Silahkan upload surat kelulusan" style="cursor:pointer"                                            
                                            readonly
                                            v-on:click="viewData(form.surat_kelulusan)"
                                            >
                                            <small>Format: .pdf</small>

                                        <input class="d-none" type="file" name="file_surat_kelulusan" id="file_surat_kelulusan" @change="previewFiles_surat_kelulusan" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_surat_kelulusan()" :disabled="upload_surat_kelulusan" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_surat_kelulusan ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="sltp">KIP (Jika Sudah Ada)</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="kip"
                                            v-model="form.kip"
                                            placeholder="Silahkan upload KIP" style="cursor:pointer"                                            
                                            readonly
                                            v-on:click="viewData(form.kip)"
                                            >
                                            <small>Format: .pdf</small>

                                        <input class="d-none" type="file" name="file_kip" id="file_kip" @change="previewFiles_kip" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_kip()" :disabled="upload_kip" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_kip ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>       
                                    
                                    <div class="form-group col-md-6">
                                        <label for="sltp">Piagam Penghargaan (Jika Sudah Ada)</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="piagam_penghargaan"
                                            v-model="form.piagam_penghargaan"
                                            placeholder="Silahkan upload Piagam Penghargaan" style="cursor:pointer"                                            
                                            readonly
                                            v-on:click="viewData(form.piagam_penghargaan)"
                                            >
                                            <small>Format: .pdf</small>

                                        <input class="d-none" type="file" name="file_piagam_penghargaan" id="file_piagam_penghargaan" @change="previewFiles_piagam_penghargaan" accept="application/pdf">
                                        <div class="input-group-append mt-2" v-if="dataDetail.member.terkirim == 0">
                                            <button v-on:click="chooseUpload_piagam_penghargaan()" :disabled="upload_piagam_penghargaan" class="btn btn-sm btn-primary rounded" type="button">
                                                <i class="fa fa-upload"></i> {{ upload_piagam_penghargaan ? 'Uploading...' : 'Pilih File' }}
                                            </button>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>

                            <small>Keterangan:
                                <br/>
                                 - Silahkan upload berkas sesuai dengan format yang telah ditentukan
                                 <br/>
                                 - Tanda (<span class="text-danger">*</span>) Wajib Diisi
                                 <br/>
                                 - Batas Maksimal Upload 5 MB</small>
                        </div>

                        <div class="col-md-4 text-center">
                            <div v-if="dataDetail.member.terkirim == 1" class="alert alert-info text-center" role="alert">
                                <b>Keterangan:</b><br/> Kamu tidak bisa melakukan perubahan ketika data sudah terkirim/sedang dalam proses verifikasi.
                            </div>

                            <button
                                :disabled="dataDetail.member.terkirim == 1 ? true:false"
                                type="submit"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span><i class="fa fa-save"></i> Simpan Data</span>
                                <span class="ml-auto">💾</span>
                            </button>
                            <button
                                v-on:click="alertBack()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Kembali</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'BerkasSiswa',
        metaInfo: {
            title: 'Upload Berkas (Ijazah/SKHUN/Pas Foto/Dll)',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                form        : {},
                submitted   : false,
                waiting     : false,
                upload_skhun : false,
                upload_ijazah : false,
                upload_surat_kelulusan : false,
                upload_pas_foto : false,
                upload_kartu_keluarga : false,
                upload_akta_kelahiran : false,
                upload_kip : false,
                upload_piagam_penghargaan : false
            }
        },
        created() {
            this.getData()
        },
        validations: {
            form: {
                pas_foto: {
                    required
                },
                kartu_keluarga: {
                    required
                },
                akta_kelahiran: {
                    required
                }
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.ppdb_formulir_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data
                        this.getIdentitas();
                    } else {
                        this.dataFetch  = false;
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            getIdentitas: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa';
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.form = res.data.data
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : this.$route.params.ppdb_formulir_id
                    }
                });
            },
            alertBack: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin kembali ke halaman sebelumnya?',
                    confirmButtonText: 'Iya, Kembali',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.goBack()
                    }
                });                
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // Validasi form
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                }

                let formData = new FormData();

                formData.append('id', this.form.id);
                formData.append('sltp', this.form.sltp);
                formData.append('skhun', this.form.skhun);
                formData.append('ijazah', this.form.ijazah);
                formData.append('surat_kelulusan', this.form.surat_kelulusan);
                formData.append('pas_foto', this.form.pas_foto);
                formData.append('kartu_keluarga', this.form.kartu_keluarga);
                formData.append('akta_kelahiran', this.form.akta_kelahiran);
                formData.append('kip', this.form.kip);
                formData.append('piagam_penghargaan', this.form.piagam_penghargaan);
                formData.append('ppdb_formulir_id',  this.$route.params.ppdb_formulir_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/berkas-siswa/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Silakan cek koneksi internet Anda.',
                    });
                    this.waiting = false;
                });
            },
            viewData: function(url="") {
                if(url) {
                    window.open(url, '_blank');                     
                }
            },
            chooseUpload_skhun: function () {
                document.getElementById("file_skhun").click()
            },
            previewFiles_skhun(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_skhun      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'skhun');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_skhun = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_ijazah: function () {
                document.getElementById("file_ijazah").click()
            },
            previewFiles_ijazah(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_ijazah      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'ijazah');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_ijazah = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_surat_kelulusan: function () {
                document.getElementById("file_surat_kelulusan").click()
            },
            previewFiles_surat_kelulusan(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_surat_kelulusan      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'surat_kelulusan');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_surat_kelulusan = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_pas_foto: function () {
                document.getElementById("file_pas_foto").click()
            },
            previewFiles_pas_foto(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_pas_foto      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'pas_foto');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_pas_foto = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_kartu_keluarga: function () {
                document.getElementById("file_kartu_keluarga").click()
            },
            previewFiles_kartu_keluarga(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_kartu_keluarga      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'kartu_keluarga');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_kartu_keluarga = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_akta_kelahiran: function () {
                document.getElementById("file_akta_kelahiran").click()
            },
            previewFiles_akta_kelahiran(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_akta_kelahiran      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'akta_kelahiran');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_akta_kelahiran = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_kip: function () {
                document.getElementById("file_kip").click()
            },
            previewFiles_kip(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_kip      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'kip');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_kip = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            },
            chooseUpload_piagam_penghargaan: function () {
                document.getElementById("file_piagam_penghargaan").click()
            },
            previewFiles_piagam_penghargaan(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.upload_piagam_penghargaan      = true;
                    
                    let formData    = new FormData();
                    formData.append('file_attachment', file);
                    formData.append('params', 'piagam_penghargaan');

                    this.$http.post(process.env.VUE_APP_APIHOST+'ppdb/berkas-siswa/save-upload', formData )
                    .then(response => {
                        this.upload_piagam_penghargaan = false;

                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getIdentitas();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    });                    
                }
            }            
        }
    }
</script>