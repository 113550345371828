<template>
    <div>
        <div v-if="userdata.type == 'ppdb_member'">
            <h5>Dashboard</h5>
            <hr />
            <div v-if="!userdata.fullname" class="d-block p-4 bg-primary-light rounded mb-4 griya-welcome-banner">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="w-100">
                        <div class="d-flex align-items-center">
                            <div class="w-100">
                                <div class="skeleton-bar">
                                    <div class="sb-md m-b-2"></div>
                                    <div class="sb-lg mt-2"></div>
                                    <div class="sb-lg sb-thin mt-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.fullname" class="d-block p-4 bg-primary-light rounded griya-welcome-banner">
                <h5 class="m-t-10">
                    🎉 Selamat Datang di Aplikasi PPDB Online, {{ userdata.fullname }}! 👋  
                </h5>
                <p>
                    Hai, calon siswa! Selamat datang di portal resmi Penerimaan Peserta Didik Baru (<b>PPDB</b>).  
                    Sekarang kamu bisa mendaftar ke <b>{{ data_school.name }}</b> dengan mudah, cepat, dan tanpa ribet! 🚀
                </p>
                <ul>
                    <li>📌 <b>Daftar Sekarang & Jadi Bagian dari {{ data_school.name }}</b></li>
                    <li>📌 <b>Pantau Status Pendaftaran Secara Real-Time</b></li>
                    <li>📌 <b>Proses Mudah & Transparan</b></li>
                </ul>
                <p>Ambil formulir pendaftaran & Pastikan semua data pendaftaran kamu sudah lengkap dan benar ya! ✍️  
                <br/>Kesempatan emas menunggu di <b>{{ data_school.name }}</b>! 🎯</p>
            </div>
        </div>

        <div v-if="userdata.type == 'ppdb_admin'">
            <h5>Dashboard</h5>
            <hr />
            <div v-if="!userdata.fullname" class="d-block p-4 bg-primary-light rounded mb-4 griya-welcome-banner">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="w-100">
                        <div class="d-flex align-items-center">
                            <div class="w-100">
                                <div class="skeleton-bar">
                                    <div class="sb-md m-b-2"></div>
                                    <div class="sb-lg mt-2"></div>
                                    <div class="sb-lg sb-thin mt-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.fullname" class="d-block p-4 bg-primary-light rounded griya-welcome-banner">
                <h5 class="m-t-10">
                    🎉 Selamat Datang di Dashboard PPDB Online, {{ userdata.fullname }}! 👋  
                </h5>
                <p>
                    Halo, panitia PPDB! Selamat datang di sistem Penerimaan Peserta Didik Baru (<b>PPDB</b>) <b>{{ data_school.name }}</b>.  
                    Gunakan dashboard ini untuk mengelola pendaftaran siswa dengan lebih mudah, cepat, dan efisien. 🚀
                </p>
                <ul>
                    <li>📌 <b>Kelola & Verifikasi Data Pendaftar</b></li>
                    <li>📌 <b>Monitoring Status Pendaftaran Secara Real-Time</b></li>
                    <li>📌 <b>Pastikan Proses PPDB Berjalan Lancar & Transparan</b></li>
                </ul>
                <p>Periksa data calon siswa secara teliti dan pastikan semua tahapan pendaftaran berjalan sesuai prosedur. ✍️  
                <br/>Bersama, kita wujudkan PPDB yang tertib dan berkualitas di <b>{{ data_school.name }}</b>! 🎯</p>
            </div>

            <h5 class="m-t-30">Statistik Jumlah Data Peserta</h5>
            <hr />
            <div class="row">
                <div v-for="(item, index) in dataUsersPpdb" :key="index" style="cursor:pointer" class="col-xl-3 col-md-3 col-lg-6 col-sm-12" data-toggle="modal" data-target="#modal_hasil_pengumuman" v-on:click="showUser(item)">
                    <div class="card prod-p-card" :style="{ backgroundColor: colors[index % colors.length] }">
                        <div class="card-body">
                            <div class="row align-items-center m-b-0">
                                <div class="col">
                                <h6 class="m-b-5 text-white">{{item.peminatan == 'Kelas Khusus Olahraga (KKO)' ? 'Kelas KKO':item.peminatan}}</h6>
                                <h3 class="m-b-0 text-white">{{ item.total_users}}</h3>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-users text-white"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="userdata.type != 'ppdb_member' && userdata.type != 'ppdb_admin'">
            <h5>Dashboard</h5>
            <hr />
            <div v-if="!userdata.fullname" class="d-block p-4 bg-primary-light rounded mb-4 griya-welcome-banner">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="w-100">
                        <div class="d-flex align-items-center">
                            <div class="w-100">
                                <div class="skeleton-bar">
                                    <div class="sb-md m-b-2"></div>
                                    <div class="sb-lg mt-2"></div>
                                    <div class="sb-lg sb-thin mt-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userdata.fullname" class="d-block p-4 bg-primary-light rounded griya-welcome-banner">
                <h4 class="m-t-10">Hallo {{userdata.fullname}}, Selamat datang di Griya Belajar!</h4>
                <div v-if="userdata.type != 'headmaster'" class="d-block h1 mb-2">Sudah siap belajar apa hari ini?</div>
                <div v-if="userdata.type == 'headmaster'" class="d-block h3 mb-2">Siap memantau perkembangan sekolah anda hari ini?</div>
                <p v-if="userdata.type != 'headmaster'" class="">Jangan lupa semangat! Karena banyak latihan dan tryout yang masih menunggu untuk
                    diselesaikan.</p>
                <p v-if="userdata.type == 'headmaster'" class="">Akses informasi terkini mengenai aktivitas siswa dan guru yang siap dipantau. Terus berikan dukungan dan pantauan terbaik untuk keberhasilan siswa dan para guru.</p>
            </div>

            <div v-if="dataFetch && !userdata.fullname">
                <p>Sedang mengambil data...</p>
            </div>

            <div v-if="!dataFetch  && userdata.fullname">
                <div v-if="userdata.type != 'proctor' && userdata.type != 'content' && userdata.type != 'headmaster'">
                    <h5 class="m-t-30">Program dari Griya Belajar</h5>
                    <hr />

                    <div class="row">
                        <div v-for="(item, index) in dataList" :key="index" class="col-xl-4 col-md-4 col-lg-6">
                            <div class="card prod-p-card" :class="item.bg">
                                <router-link :to="{name : item.url}">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                            <h6 class="m-b-5 text-white">{{item.name}}</h6>
                                            <h3 class="m-b-0 text-white">{{item.total}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-archive text-white"></i>
                                        </div>
                                    </div>
                                </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="userdata.type == 'headmaster'">
                    <h5 class="m-t-30">Statistik Data Pengguna</h5>
                    <hr />
                    <div class="row">
                        <div style="cursor:pointer" v-on:click="goToMenu('headmaster_student')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-success">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                        <h6 class="m-b-5 text-white">Total Siswa</h6>
                                        <h3 class="m-b-0 text-white">{{dataHeadmaster['member']['total']}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-users text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="cursor:pointer" v-on:click="goToMenu('headmaster_teacher')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-info">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                        <h6 class="m-b-5 text-white">Total Guru</h6>
                                        <h3 class="m-b-0 text-white">{{dataHeadmaster['content']['total']}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-users text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="cursor:pointer" v-on:click="goToMenu('headmaster_proctor')" class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-warning">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                        <h6 class="m-b-5 text-white">Total Proktor</h6>
                                        <h3 class="m-b-0 text-white">{{dataHeadmaster['proctor']['total']}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-users text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-primary">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                        <h6 class="m-b-5 text-white">Total Keseluruhan</h6>
                                        <h3 class="m-b-0 text-white">
                                            {{dataHeadmaster['member']['total']+dataHeadmaster['content']['total']+dataHeadmaster['proctor']['total']}}
                                        </h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-users text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card card-theory h-100">
                                <div class="card-body h-100">
                                    <h6 class="job-card-desc">
                                        Grafik Jenis Kelamin (Siswa)
                                    </h6>

                                    <div v-if="dataHeadmaster['member']['total'] > 0">
                                        <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesSiswa"></apexchart>
                                    </div>

                                    <div class="text-center m-t-40" v-if="dataHeadmaster['member']['total'] < 1">
                                        <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                        <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card card-theory h-100">
                                <div class="card-body h-100">
                                    <h6 class="job-card-desc">
                                        Grafik Jenis Kelamin (Guru)
                                    </h6>

                                    <div v-if="dataHeadmaster['content']['total'] > 0">
                                        <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesGuru"></apexchart>
                                    </div>

                                    <div class="text-center m-t-40" v-if="dataHeadmaster['content']['total'] < 1">
                                        <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                        <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card card-theory h-100">
                                <div class="card-body h-100">
                                    <h6 class="job-card-desc">
                                        Grafik Jenis Kelamin (Proktor)
                                    </h6>

                                    <div v-if="dataHeadmaster['proctor']['total'] > 0">
                                        <apexchart  height="300" type="bar" :options="chartOptions" :series="seriesProktor"></apexchart>
                                    </div>

                                    <div class="text-center m-t-40" v-if="dataHeadmaster['proctor']['total'] < 1">
                                        <img style="width:300px;max-width:100%" src="assets/images/empty-box.svg">
                                        <h6 class="m-t-10">Grafik tidak tersedia</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="userdata.type == 'proctor'">
                    <h5 class="m-t-30">Statistik Data</h5>
                    <hr />
                    <div class="row">
                        <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-success">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                        <h6 class="m-b-5 text-white">Total Peserta</h6>
                                        <h3 class="m-b-0 text-white">{{dataProctor.total_user}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-users text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-info">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                    <h6 class="m-b-5 text-white">Tryout SNBT</h6>
                                    <h3 class="m-b-0 text-white">{{dataProctor.total_utbk}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-file text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-warning">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                    <h6 class="m-b-5 text-white">Tryout Kedinasan</h6>
                                    <h3 class="m-b-0 text-white">{{dataProctor.total_kedinasan}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-file text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-md-3 col-lg-6 col-sm-12">
                            <div class="card prod-p-card bg-danger">
                                <div class="card-body">
                                    <div class="row align-items-center m-b-0">
                                        <div class="col">
                                    <h6 class="m-b-5 text-white">Tryout AKM</h6>
                                    <h3 class="m-b-0 text-white">{{dataProctor.total_akm}}</h3>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-file text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modal_hasil_pengumuman" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-users"></i> Daftar Peserta</div>
                            <div>
                                <button id="btnCloseModalStatus" type="button" class="btn btn-link btn-sm p-0 text-primary"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Peminatan</div>
                            <div class="font-weight-600">{{ data_modal.peminatan }}</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                            <div class="mr-3">Total Peserta Terdaftar</div>
                            <div class="font-weight-600">{{ data_modal.total_users }} Orang</div>
                        </div>
                        <input type="text" class="form-control" placeholder="Cari data berdasarkan nama peserta atau no pendaftaran..." v-model="search_modal" />
                        <div class="table-responsive mt-3">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td style="width: 5%;" class="text-center">No</td>
                                        <td>Nama Peserta</td>
                                        <td style="width: 5%;" class="text-center">No Pendaftaran</td>
                                        <td style="width: 15%;" class="text-center">Status Pendaftaran</td>
                                    </tr>
                                </thead>
                                <tbody v-if="data_modal.detail_users.length > 0">
                                    <tr v-for="(itemModal, indexModal) in data_modal.detail_users.filter(user =>  user.fullname.toLowerCase().includes(search_modal.toLowerCase()) || user.no_pendaftaran.toLowerCase().includes(search_modal.toLowerCase()) )" :key="indexModal">
                                        <td class="text-center">{{ ++indexModal }}</td>
                                        <td>{{ itemModal.fullname }}</td>
                                        <td>{{ itemModal.no_pendaftaran }}</td>                                        
                                        <td>
                                            <span v-if="itemModal.status_pendaftaran == 'belum_melengkapi'" class="badge badge-danger">Belum Lengkap</span> 
                                            <span v-if="itemModal.status_pendaftaran == 'menunggu_verifikasi'" class="badge badge-warning" style="color: #222 !important;">Menunggu Verifikasi</span> 
                                            <span v-if="itemModal.status_pendaftaran == 'lolos_verifikasi'" class="badge badge-success" style="color: #222 !important;">Lolos Verifikasi</span> 
                                            <span v-if="itemModal.status_pendaftaran == 'gagal_verifikasi'" class="badge badge-danger">Gagal Verifikasi (Ditolak)</span> 
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data_modal.detail_users.length < 1">
                                    <tr>
                                        <td colspan="4">Data tidak tersedia</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>          
            </div>          
        </div>
    </div>
</template>

<script>
    import {
        Glide,
        GlideSlide
    } from 'vue-glide-js'
    import 'vue-glide-js/dist/vue-glide.css'
    import {
        mapState
    } from 'vuex'

    export default {
        name: 'Members',
        metaInfo: {
            title: 'Dashboard',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            [Glide.name]: Glide,
            [GlideSlide.name]: GlideSlide
        },
        data() {
            return {
                colors: ['#57B4BA', '#A5B68D', '#D76C82', '#4F959D', '#EC7FA9', '#7A73D1', '#2DAA9E', '#D98324', '#A31D1D', '#497D74'],
                dataFetch   : false,
                data_school   : [],
                dataList    : [],
                dataProctor : [],
                dataHeadmaster : [],
                dataUsersPpdb   : [],
                data_modal  : {
                    detail_users : []
                },
                search_modal : '',
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019'],
                    xaxis: {
                        categories: ['Jenis Kelamin']
                    },
                },
                seriesSiswa: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
                seriesGuru: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
                seriesProktor: [
                    {
                        name: 'Laki-Laki (L)',
                        data: [0]
                    },
                    {
                        name: 'Perempuan (P)',
                        data: [0]
                    },
                    {
                        name: 'Belum diatur (X)',
                        data: [0]
                    }
                ],
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData');
        },
        computed: mapState(['userdata']),
        created() {
            this.getData();
        },
        methods: {
            goToMenu: function(menu=null) {
                this.$router.push({ name: menu });
            },
            getData: function() {
                let uri     = process.env.VUE_APP_APIHOST+'dashboard/package';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataList = res.data.data;
                        this.getStatistic(res.data.type);
                        if(res.data.type == 'ppdb_member') {
                            this.getUserInfo();
                        }
                        if(res.data.type == 'ppdb_admin') {
                            this.getStatisticPpdb();
                        }
                    }
                    this.dataFetch = false;                
                });
            },
            getStatistic: function(type="") {
                if(type == 'proctor') {
                    let uri     = process.env.VUE_APP_APIHOST+'dashboard/statistic';
                    this.dataFetch  = true;
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dataProctor = res.data.data;
                        }
                        this.dataFetch = false;                
                    });
                }

                if(type == 'headmaster') {
                    let uri     = process.env.VUE_APP_APIHOST+'dashboard/statistic-headmaster';
                    this.dataFetch  = true;
                    this.$http.get(uri).then(res => {
                        if(res.data.status) {
                            this.dataHeadmaster = res.data.data;

                            if(this.dataHeadmaster['member']['total']) {
                                this.seriesSiswa = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['member']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['member']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['member']['gender']['X']]
                                    }
                                ];
                            }

                            if(this.dataHeadmaster['content']['total']) {
                                this.seriesGuru = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['content']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['content']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['content']['gender']['X']]
                                    }
                                ];
                            }

                            if(this.dataHeadmaster['proctor']['total']) {
                                this.seriesProktor = [
                                    {
                                        name: 'Laki-Laki (L)',
                                        data: [this.dataHeadmaster['proctor']['gender']['L']]
                                    },
                                    {
                                        name: 'Perempuan (P)',
                                        data: [this.dataHeadmaster['proctor']['gender']['P']]
                                    },
                                    {
                                        name: 'Belum diatur (X)',
                                        data: [this.dataHeadmaster['proctor']['gender']['X']]
                                    }
                                ];
                            }
                        }
                        this.dataFetch = false;                
                    });
                }
            },
            getUserInfo: function() {
                let uri     = process.env.VUE_APP_APIHOST+'user/school';
                this.dataFetch  = true;
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.data_school = res.data.data
                    }
                    this.dataFetch = false;                
                });
            },
            getStatisticPpdb: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/ppdb/statistic-users';
                this.dataFetch  = true;
                this.$http.get(uri).then(res => {
                    if(res.data.status) {
                        this.dataUsersPpdb = res.data.data
                    }
                    this.dataFetch = false;                
                });
            },
            showUser: function(item) {
                this.data_modal = item;
            }
        },
    }
</script>