<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(!dataFetch && is_griyaexam == 0)" class="alert alert-danger text-center" role="alert">
            <b>
                Saat ini, sistem kami mendeteksi bahwa kamu tidak menggunakan Aplikasi Griya Belajar Exam!<br/>
                <hr/>
                Harap diingat, seluruh aktivitas dipantau oleh sistem kami. Terima kasih!
            </b>
        </div>

        <div v-if="!dataFetch" class="page-header">

            <GriyaExam v-if="show_alert"></GriyaExam>

            <div v-if="!show_alert" class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tryout CBT</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Waktu</div>
                                <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Jumlah Keseluruhan Soal</div>
                                <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                            </div>

                            <div class="alert alert-info m-t-10" role="alert">
                                <b>Perhatian !</b>
                                <hr/>
                                <ol>
                                    <li>Gunakan browser versi terbaru supaya website dapat diakses dengan lancar tanpa masalah.</li>
                                    <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout</li>
                                    <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="!dataDetail.finished">
                            <button v-if="dataDetail.status_pakta_integritas == '0'" type="button" data-toggle="modal" data-target="#instructionQuiz" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                            <button v-if="dataDetail.status_pakta_integritas == '1'" type="button" data-toggle="modal" data-target="#instructionQuizPakta" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        </div>
                        <div v-if="dataDetail.finished">
                            <router-link v-if="dataDetail.answer_id" :to="{name : 'member_tryout_cbt_result_detail', params: { result_id: dataDetail.answer_id }}" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-bars"></i> Lihat Hasil</router-link>
                        </div>
                        <a href="javascript:void(0)" onclick="window.history.back();" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-back"></i> Kembali</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div style="" class="modal" id="instructionQuiz" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0 text-center">
                        <div class="align-items-center justify-content-between text-sm">
                            <img style="width: 30%; height: auto;" class="mb-5"
                                src="https://scolacdn.com/frontend/images/sign-warning-icon.png" />
                            <h5>Untuk lanjut ke pengerjaan soal mohon diperhatikan beberapa langkah berikut: </h5>
                        </div>
                    </div>
                    <div class="d-block p-3">
                    <ol class="mb-4">
                        <li>Pastikan internet stabil.</li>
                        <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout.</li>
                        <li>Disarankan menggunakan versi browser terbaru.</li>
                        <li>Kerjakan soal sesuai dengan waktu yang ditentukan.</li>
                        <li>Pastikan semua jawaban terisi</li>
                        <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                    </ol>
                        <button data-dismiss="modal" v-on:click="startTyout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai</button>
                    </div>
                </div>
            </div>
        </div>

        <div style="" class="modal" id="instructionQuizPakta" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0 text-center">
                        <div class="align-items-center justify-content-between text-sm">
                            <img style="width: 30%; height: auto;" class="mb-5"
                                src="https://scolacdn.com/frontend/images/sign-warning-icon.png" />
                            <p style="font-size:15px">KETIK ULANG PAKTA INTEGRASI BERIKUT INI</p>
                            <h6>
                                "<b>{{ dataDetail.text_pakta_integritas }}</b>"
                            </h6>
                        </div>
                    </div>
                    <div class="d-block p-3">
                        <input type="text" class="form-control" 
                            placeholder="Ketik ulang teks di atas secara lengkap untuk melanjutkan ujian..." 
                            v-model="text_pakta_integritas">
                        <button data-dismiss="modal" v-on:click="startTyout" type="button" 
                            class="btn btn-primary rounded btn-block m-t-10" 
                            :disabled="text_pakta_integritas !== dataDetail.text_pakta_integritas">
                            <i class="fa fa-edit"></i> Simpan Pakta Integrasi & Mulai Mengerjakan
                        </button>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>

<script>
    import GriyaExam from '../../../static/GriyaExam.vue';
    
    export default {
        name: 'TryoutCbtOverview',
        metaInfo: {
            title: 'Persiapan Tryout CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            GriyaExam
        },
        data() {
            return {
                submitted : false,
                waiting : false,
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
                randomCode      : (Math.random() + 1).toString(36).substring(2),                
                show_alert      : false,
                allow_exam      : 1,
                is_android      : false,
                check_web_view  : '',
                is_griyaexam    : 1,
                text_pakta_integritas : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/tryout/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;

                        if(this.dataDetail.safe_exam == '1') {                        
                            this.dataFetch  = false;
                            this.checkDevice();
                        }

                        if(this.allow_exam == 1) {                            
                            if(!this.dataDetail.owned) {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({
                                            name: 'home'
                                        });
                                    }
                                });
                            } else {
                                if(this.dataDetail.opened == '1') {
                                    if(this.dataDetail.ongoing) {
                                        this.$router.push({
                                            name: 'member_tryout_cbt_start',
                                            params: {
                                                slug        : this.$route.params.slug
                                            }
                                        });
                                    } else {
                                        this.dataFetch  = false;         
                                    }
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        html: "Akses tidak diperbolehkan!<br/>Tryout ini hanya bisa di akses pada tanggal :<br/><b>"+this.dataDetail.text_start_date+"</b>",
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: false
                                    }).then((result) => {
                                        if (result.value) {
                                            this.$router.push({
                                                name: 'member_tryout_cbt'
                                            });
                                        }
                                    });
                                }
                            }
                        } else {
                            this.show_alert = true;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            checkDevice: function() {
                var isAndroid = /Android/.test(navigator.userAgent);
                console.log(navigator.userAgent)
                if (isAndroid) {
                    this.is_android = true;
                    this.checkWebView();

                    if (this.check_web_view == 'iOS webview' || this.check_web_view == 'Android webview') {                        
                        if (navigator.userAgent.includes('griyaexam')) {
                            this.is_griyaexam   = 1;
                            this.allow_exam     = 1;
                        } else {
                            this.is_griyaexam   = 0;
                            this.allow_exam     = 0;
                        }
                    } else {
                        this.allow_exam = 0;
                    }
                }                
            },
            checkWebView: function() {
                var standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

                if (ios) {
                    if (!standalone && safari) {
                        this.check_web_view = 'Safari Browser'
                    } else if (!standalone && !safari) {
                        this.check_web_view = 'iOS webview';
                    }
                } else {
                    if (userAgent.includes('wv')) {
                        this.check_web_view = 'Android webview'
                    } else {
                        this.check_web_view = 'Chrome Browser'
                    }
                }
            },
            startTyout: function() {
                this.$router.push({
                    name: 'member_tryout_cbt_start',
                    params: {
                        slug : this.$route.params.slug,
                    }
                });
            },
        }
    }
</script>