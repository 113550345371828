<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a v-on:click="goBack()" href="javascript:void(0)"><i class="fa fa-arrow-left m-r-10"></i></a>
                                Data Diri / Identitas Siswa
                            </h5>
                        </div>
                    </div>
                </div>

                <form v-on:submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-1 mt-2">
                                <div class="row">
                                    <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="hidden" name="id" v-model="form.id">

                                    <div class="form-group col-md-12">
                                        <label for="nama_lengkap">Nama Lengkap  <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nama_lengkap"
                                            placeholder="Masukan data..." v-model="form.nama_lengkap"
                                            :class="{ 'is-invalid': submitted && $v.form.nama_lengkap.$error }">
                                        <div v-if="submitted && !$v.form.nama_lengkap.required" class="invalid-feedback">Nama Lengkap wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="nisn">NISN  <span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nisn"
                                            placeholder="Masukan data..." v-model="form.nisn"
                                            :class="{ 'is-invalid': submitted && $v.form.nisn.$error }">
                                        <div v-if="submitted && !$v.form.nisn.required" class="invalid-feedback">NISN wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="no_kk">No KK<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="no_kk"
                                            placeholder="Masukan data..." v-model="form.no_kk"
                                            :class="{ 'is-invalid': submitted && $v.form.no_kk.$error }">
                                        <div v-if="submitted && !$v.form.no_kk.required" class="invalid-feedback">No KK wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="nik">NIK<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="nik"
                                            placeholder="Masukan data..." v-model="form.nik"
                                            :class="{ 'is-invalid': submitted && $v.form.nik.$error }">
                                        <div v-if="submitted && !$v.form.nik.required" class="invalid-feedback">NIK wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="kip">KIP</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="kip"
                                            placeholder="Masukan data..." v-model="form.kip">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="tempat_lahir">Tempat Lahir<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="tempat_lahir"
                                            placeholder="Masukan data..." v-model="form.tempat_lahir"
                                            :class="{ 'is-invalid': submitted && $v.form.tempat_lahir.$error }">
                                        <div v-if="submitted && !$v.form.tempat_lahir.required" class="invalid-feedback">Tempat Lahir wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="tanggal_lahir">Tanggal Lahir<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="date" class="form-control" id="tanggal_lahir"
                                            v-model="form.tanggal_lahir" :class="{ 'is-invalid': submitted && $v.form.tanggal_lahir.$error }">
                                        <div v-if="submitted && !$v.form.tanggal_lahir.required" class="invalid-feedback">Tanggal Lahir wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="jenis_kelamin">Jenis Kelamin<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="jenis_kelamin"
                                            v-model="form.jenis_kelamin" :class="{ 'is-invalid': submitted && $v.form.jenis_kelamin.$error }">
                                            <option value="">Pilih Jenis Kelamin</option>
                                            <option value="Laki-laki">Laki-laki</option>
                                            <option value="Perempuan">Perempuan</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.jenis_kelamin.required" class="invalid-feedback">Jenis Kelamin wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="agama">Agama  <span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1 ? true:false" class="form-control" id="agama" v-model="form.agama" 
                                            :class="{ 'is-invalid': submitted && $v.form.agama.$error }">
                                            <option value="" disabled>Pilih Agama...</option>
                                            <option value="Islam">Islam</option>
                                            <option value="Kristen Protestan">Kristen Protestan</option>
                                            <option value="Kristen Katolik">Kristen Katolik</option>
                                            <option value="Hindu">Hindu</option>
                                            <option value="Buddha">Buddha</option>
                                            <option value="Konghucu">Konghucu</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.agama.required" class="invalid-feedback">
                                            Agama wajib dipilih!
                                        </div>
                                    </div>


                                    <div class="form-group col-md-6">
                                        <label for="jumlah_saudara">Jumlah Saudara<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="number" class="form-control" id="jumlah_saudara"
                                            placeholder="Masukan data..." v-model="form.jumlah_saudara"
                                            :class="{ 'is-invalid': submitted && $v.form.jumlah_saudara.$error }">
                                        <div v-if="submitted && !$v.form.jumlah_saudara.required" class="invalid-feedback">Jumlah Saudara wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="anak_ke">Anak Ke<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="number" class="form-control" id="anak_ke"
                                            placeholder="Masukan data..." v-model="form.anak_ke"
                                            :class="{ 'is-invalid': submitted && $v.form.anak_ke.$error }">
                                        <div v-if="submitted && !$v.form.anak_ke.required" class="invalid-feedback">Anak Ke wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="hobi">Hobi<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="hobi"
                                            placeholder="Masukan data..." v-model="form.hobi"
                                            :class="{ 'is-invalid': submitted && $v.form.hobi.$error }">
                                        <div v-if="submitted && !$v.form.hobi.required" class="invalid-feedback">Hobi wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="cita_cita">Cita Cita<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="cita_cita"
                                            placeholder="Masukan data..." v-model="form.cita_cita"
                                            :class="{ 'is-invalid': submitted && $v.form.cita_cita.$error }">
                                        <div v-if="submitted && !$v.form.cita_cita.required" class="invalid-feedback">Cita Cita wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="no_handphone">No Handphone<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="no_handphone"
                                            placeholder="Masukan data..." v-model="form.no_handphone"
                                            :class="{ 'is-invalid': submitted && $v.form.no_handphone.$error }">
                                        <div v-if="submitted && !$v.form.no_handphone.required" class="invalid-feedback">No Handphone wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="email">Email</label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="email" class="form-control" id="email"
                                            placeholder="Masukan data..." v-model="form.email">
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="yang_membiayai_sekolah">Yang Membiayai Sekolah<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="yang_membiayai_sekolah" v-model="form.yang_membiayai_sekolah"
                                            :class="{ 'is-invalid': submitted && $v.form.yang_membiayai_sekolah.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="orang tua">Orang Tua</option>
                                            <option value="wali">Wali</option>
                                            <option value="kakek/nenek">Kakek/Nenek</option>
                                            <option value="lainnya">Lainnya</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.yang_membiayai_sekolah.required" class="invalid-feedback">Yang Membiayai Sekolah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="kebutuhan_disabilitas">Kebutuhan Disabilitas<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="kebutuhan_disabilitas" v-model="form.kebutuhan_disabilitas"
                                            :class="{ 'is-invalid': submitted && $v.form.kebutuhan_disabilitas.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="ya">Ya</option>
                                            <option value="tidak">Tidak</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.kebutuhan_disabilitas.required" class="invalid-feedback">Kebutuhan Disabilitas wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="kebutuhan_khusus">Kebutuhan Khusus<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="kebutuhan_khusus" v-model="form.kebutuhan_khusus"
                                            :class="{ 'is-invalid': submitted && $v.form.kebutuhan_khusus.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="ya">Ya</option>
                                            <option value="tidak">Tidak</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.kebutuhan_khusus.required" class="invalid-feedback">Kebutuhan Khusus wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="imunisasi">Imunisasi<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="imunisasi" v-model="form.imunisasi"
                                            :class="{ 'is-invalid': submitted && $v.form.imunisasi.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="polio">Polio</option>
                                            <option value="COVID-19">COVID-19</option>
                                            <option value="lainnya">Lainnya</option>
                                        </select>
                                        <input v-if="form.imunisasi && (form.imunisasi != 'polio' || form.imunisasi != 'COVID-19')" type="text" class="form-control mt-2" placeholder="Masukan opsi lainnya..." v-model="form.imunisasi_lainnya">
                                        <div v-if="submitted && !$v.form.imunisasi.required" class="invalid-feedback">Imunisasi wajib diisi!</div>
                                    </div>


                                    <div class="form-group col-md-6">
                                        <label for="status_tempat_tinggal">Status Tempat Tinggal<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="status_tempat_tinggal" v-model="form.status_tempat_tinggal"
                                            :class="{ 'is-invalid': submitted && $v.form.status_tempat_tinggal.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="milik sendiri">Milik Sendiri</option>
                                            <option value="sewa/kontrak">Sewa/Kontrak</option>
                                            <option value="milik kerabat/sodara">Milik Kerabat/Sodara</option>
                                            <option value="milik orang tua">Milih Orang Tua</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.status_tempat_tinggal.required" class="invalid-feedback">Status Tempat Tinggal wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="jarak_tinggal_madrasah">Jarak Tinggal Madrasah<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="jarak_tinggal_madrasah"
                                            placeholder="Masukan data..." v-model="form.jarak_tinggal_madrasah"
                                            :class="{ 'is-invalid': submitted && $v.form.jarak_tinggal_madrasah.$error }">
                                        <div v-if="submitted && !$v.form.jarak_tinggal_madrasah.required" class="invalid-feedback">Jarak Tinggal Madrasah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="waktu_tempuh">Waktu Tempuh<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="waktu_tempuh"
                                            placeholder="Masukan data..." v-model="form.waktu_tempuh"
                                            :class="{ 'is-invalid': submitted && $v.form.waktu_tempuh.$error }">
                                        <div v-if="submitted && !$v.form.waktu_tempuh.required" class="invalid-feedback">Waktu Tempuh wajib diisi!</div>
                                    </div>


                                    <div class="form-group col-md-6">
                                        <label for="transportasi_ke_sekolah">Transportasi ke Sekolah<span class="text-danger">*</span></label>
                                        <select :disabled="dataDetail.member.terkirim == 1" class="form-control" id="transportasi_ke_sekolah" v-model="form.transportasi_ke_sekolah"
                                            :class="{ 'is-invalid': submitted && $v.form.transportasi_ke_sekolah.$error }">
                                            <option value="">Pilih...</option>
                                            <option value="jalan kaki">Jalan Kaki</option>
                                            <option value="sepeda">Sepeda</option>
                                            <option value="sepeda motor">Sepeda Motor</option>
                                            <option value="mobil">Mobil</option>
                                            <option value="antar/jemput">Antar/Jemput</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.transportasi_ke_sekolah.required" class="invalid-feedback">Transportasi ke Sekolah wajib diisi!</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="alamat">Alamat<span class="text-danger">*</span></label>
                                        <input :disabled="dataDetail.member.terkirim == 1 ? true:false" type="text" class="form-control" id="alamat"
                                            placeholder="Masukan data..." v-model="form.alamat"
                                            :class="{ 'is-invalid': submitted && $v.form.alamat.$error }">
                                        <div v-if="submitted && !$v.form.alamat.required" class="invalid-feedback">alamat wajib diisi!</div>
                                    </div>
                                </div>
                            </div>

                            <small>Keterangan: Tanda (<span class="text-danger">*</span>) Wajib Diisi</small>
                        </div>

                        <div class="col-md-4 text-center">
                            <div v-if="dataDetail.member.terkirim == 1" class="alert alert-info text-center" role="alert">
                                <b>Keterangan:</b><br/> Kamu tidak bisa melakukan perubahan ketika data sudah terkirim/sedang dalam proses verifikasi.
                            </div>

                            <button
                                :disabled="dataDetail.member.terkirim == 1 ? true:false"
                                type="submit"
                                class="d-flex justify-content-between align-items-center btn btn-primary rounded btn-block m-t-10">
                                <span><i class="fa fa-save"></i> Simpan Data</span>
                                <span class="ml-auto">💾</span>
                            </button>
                            <button
                                v-on:click="alertBack()"
                                type="button"
                                class="d-flex justify-content-between align-items-center btn btn-outline-primary rounded btn-block m-t-10">
                                <span>Kembali</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'IdentitasDiri',
        metaInfo: {
            title: 'Data Diri / Identitas Siswa',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : false,
                dataDetail  : [],
                form        : {},
                submitted   : false,
                waiting     : false
            }
        },
        created() {
            this.getData()
        },
        validations: {
            form: {
                nama_lengkap: {
                    required
                },
                nisn: {
                    required
                },
                no_kk: {
                    required
                },
                nik: {
                    required
                },
                tempat_lahir: {
                    required
                },
                tanggal_lahir: {
                    required
                },
                jenis_kelamin: {
                    required
                },
                agama: {
                    required
                },
                jumlah_saudara: {
                    required
                },
                anak_ke: {
                    required
                },
                hobi: {
                    required
                },
                cita_cita: {
                    required
                },
                no_handphone: {
                    required
                },
                yang_membiayai_sekolah: {
                    required
                },
                kebutuhan_disabilitas: {
                    required
                },
                kebutuhan_khusus: {
                    required
                },
                imunisasi: {
                    required
                },
                status_tempat_tinggal: {
                    required
                },
                jarak_tinggal_madrasah: {
                    required
                },
                waktu_tempuh: {
                    required
                },
                transportasi_ke_sekolah: {
                    required
                },
                alamat: {
                    required
                }
            }
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/formulir-detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id        : this.$route.params.ppdb_formulir_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail = res.data.data
                        this.getIdentitas();
                    } else {
                        this.dataFetch  = false;
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'member_ppdb_formulir' });
                            }
                        });
                    }
                });
            },
            getIdentitas: function() {
                let uri         = process.env.VUE_APP_APIHOST+'ppdb/identitas-siswa';
                this.$http.get(uri).then(res => {
                    this.dataFetch  = false;         
                    if(res.data.status) {
                        this.form = res.data.data

                        if(this.form.imunisasi == null) {
                            this.form.imunisasi = '';
                        } else {
                            if(this.form.imunisasi != 'polio' || this.form.imunisasi != 'COVID-19') {
                                this.form.imunisasi_lainnya = this.form.imunisasi;
                                this.form.imunisasi = 'lainnya';
                            }
                        }
                    }
                });
            },
            goBack: function() {
                this.$router.push({
                    name: 'member_ppdb_formulir_detail',
                    params : {
                        id : this.$route.params.ppdb_formulir_id
                    }
                });
            },
            alertBack: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Informasi',
                    html: 'Apakah kamu yakin ingin kembali ke halaman sebelumnya?',
                    confirmButtonText: 'Iya, Kembali',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                    if (result.value) {
                        this.goBack()
                    }
                });
            },
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // Validasi form
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                }

                let formData = new FormData();

                if(this.form.imunisasi == 'lainnya') {
                    this.form.imunisasi = this.form.imunisasi_lainnya;
                }
                formData.append('id', this.form.id);
                formData.append('nama_lengkap', this.form.nama_lengkap);
                formData.append('nisn', this.form.nisn);
                formData.append('no_kk', this.form.no_kk);
                formData.append('nik', this.form.nik);
                formData.append('kip', this.form.kip);
                formData.append('tempat_lahir', this.form.tempat_lahir);
                formData.append('tanggal_lahir', this.form.tanggal_lahir);
                formData.append('jenis_kelamin', this.form.jenis_kelamin);
                formData.append('agama', this.form.agama);
                formData.append('jumlah_saudara', this.form.jumlah_saudara);
                formData.append('anak_ke', this.form.anak_ke);
                formData.append('hobi', this.form.hobi);
                formData.append('cita_cita', this.form.cita_cita);
                formData.append('no_handphone', this.form.no_handphone);
                formData.append('email', this.form.email);
                formData.append('yang_membiayai_sekolah', this.form.yang_membiayai_sekolah);
                formData.append('kebutuhan_disabilitas', this.form.kebutuhan_disabilitas);
                formData.append('kebutuhan_khusus', this.form.kebutuhan_khusus);
                formData.append('imunisasi', this.form.imunisasi);
                formData.append('status_tempat_tinggal', this.form.status_tempat_tinggal);
                formData.append('jarak_tinggal_madrasah', this.form.jarak_tinggal_madrasah);
                formData.append('waktu_tempuh', this.form.waktu_tempuh);
                formData.append('transportasi_ke_sekolah', this.form.transportasi_ke_sekolah);
                formData.append('alamat', this.form.alamat);
                formData.append('ppdb_formulir_id',  this.$route.params.ppdb_formulir_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'ppdb/identitas-siswa/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.goBack();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Silakan cek koneksi internet Anda.',
                    });
                    this.waiting = false;
                });
            }
        }
    }
</script>